import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@app/models/customer';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CountryService } from '@services/country.service';
import { StateService } from '@services/state.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  @Input() userExists: boolean;

  primaryColor: string;
  personalDetailsFormGroup: UntypedFormGroup;
  companyDetailsFormGroup: UntypedFormGroup;
  otherDetailsFormGroup: UntypedFormGroup;
  userForm: UntypedFormGroup;
  index: number;
  imageSrc: string;
  countries = [];
  states = [];
  defaultCompanyCountry: string;
  userProfilePic: string;
  profileImageFromAws: string;
  professionalDesignationsOptions = environment.ProfessionalDesignationsOptions;
  languageSpokenOptions = environment.LanguageSpokenOptions;
  industriesServedOptions = environment.IndustriesServedOptions;
  servicesProvidedOptions = environment.ServicesProvidedOptions;
  defaultRockstarUrl: string;
  urlReg = /^(https?:\/\/)(www.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  separateDialCode = true;
  searchCountryField = SearchCountryField.All;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private countryService: CountryService,
    private stateService: StateService,
    private ngxService: NgxUiLoaderService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) {
    this.defaultRockstarUrl = String(moment().unix());
    this.index = 1;
    this.defaultCompanyCountry = 'United States';
    this.userProfilePic = 'assets/images/male.png';
    this.countryService.getCountriesList().then((countriesList: any) => {
      this.logger.info('INFO: -> AddUserComponent -> this.countryService.getCountriesList -> countriesList', countriesList);
      this.countries = countriesList?.countries;
    }).catch(error => {
      this.logger.error('ERROR: -> AddUserComponent -> this.countryService.getCountriesList -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails('Get Countries List', error.error);
    });
    this.stateService.getStateByCountry(this.defaultCompanyCountry).then((statesList: any) => {
      this.logger.info('INFO: -> AddUserComponent -> this.stateService.getStateByCountry -> statesList', statesList);
      this.states = statesList?.states;
    }).catch(error => {
      this.logger.error('ERROR: -> AddUserComponent -> this.countryService.getStatesList -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails('Get States List', error.error);
    });
    this.personalDetailsFormGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(75)]],
      lastName: ['', [Validators.required, Validators.maxLength(75)]],
      companyName: ['', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(20)]],
      rockStarProfileName: [this.defaultRockstarUrl, Validators.required],
      phoneNumber: [''],
      website: ['', Validators.pattern(this.urlReg)],
      userDetails: [''],
      country: [this.defaultCompanyCountry, Validators.required]
    });
    this.companyDetailsFormGroup = this.fb.group({
      yearInBusiness: [0, [Validators.pattern(/\-?\d*\.?\d{1,2}/), Validators.min(0), Validators.max(100)]],
      phoneNumber: [''],
      companyDetails: [''],
      website: ['', Validators.pattern(this.urlReg)],
      address: this.fb.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [this.defaultCompanyCountry],
        zipCode: [''],
        latitude: [''],
        longitude: ['']
      })
    });
    const servicesProvidedGroup: any = {};
    this.servicesProvidedOptions.forEach(services => {
      servicesProvidedGroup[services.control] = new UntypedFormControl(false);
    });
    const industriesServed: any = {};
    this.industriesServedOptions.forEach(industry => {
      industriesServed[industry.control] = new UntypedFormControl(false);
    });
    const languageSpoken: any = {};
    this.languageSpokenOptions.forEach(language => {
      languageSpoken[language.control] = new UntypedFormControl(false);
    });
    const professionalDesignations: any = {};
    this.professionalDesignationsOptions.forEach(professionalDesignation => {
      professionalDesignations[professionalDesignation.control] = new UntypedFormControl(false);
    });
    this.otherDetailsFormGroup = this.fb.group({
      hideEmailInRockstar: [false],
      hidePhoneNoInRockstar: [false],
      serviceProvided: this.fb.group(servicesProvidedGroup),
      languageSpoken: this.fb.group(languageSpoken),
      industriesServed: this.fb.group(industriesServed),
      professionalDesignations: this.fb.group(professionalDesignations)
    });
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail && this.userExists) {
      this.ngxService.startLoader('add-profile-loader');
      this.userService.getUserDetails(this.userEmail).then((customerDetails: Customer) => {
        this.logger.info('INFO: -> AddUserComponent -> this.userService.getUserDetails -> customerDetails', customerDetails);
        this.profileImageFromAws = customerDetails?.profileImage;
        this.personalDetailsFormGroup.patchValue({
          firstName: customerDetails?.firstName,
          lastName: customerDetails?.lastName,
          companyName: customerDetails?.company?.name,
          title: customerDetails?.jobTitle,
          rockStarProfileName: customerDetails?.rockStarProfileName ? customerDetails?.rockStarProfileName : this.defaultRockstarUrl,
          phoneNumber: customerDetails?.phoneNumberObj ? customerDetails?.phoneNumberObj  : customerDetails?.phoneNumber,
          website: customerDetails?.website,
          userDetails: customerDetails?.aboutCustomer,
          country: customerDetails?.country
        });
        this.companyDetailsFormGroup.patchValue({
          yearInBusiness: customerDetails?.company?.yearsInBusiness,
          phoneNumber: customerDetails?.company?.phoneNumberObj ?
          customerDetails?.company?.phoneNumberObj :
          customerDetails?.company?.phoneNumber,
          companyDetails: customerDetails?.company?.about,
          website: customerDetails?.company?.website,
          address: {
            addressLine1: customerDetails?.company?.address?.addressLine1,
            addressLine2: customerDetails?.company?.address?.addressLine2,
            city: customerDetails?.company?.address?.city,
            state: customerDetails?.company?.address?.state,
            country: customerDetails?.company?.address?.country,
            zipCode: customerDetails?.company?.address?.zipCode,
            latitude: customerDetails?.company?.address?.latitude,
            longitude: customerDetails?.company?.address?.longitude
          }
        });
        const servicesProvided: any = {};
        this.servicesProvidedOptions.forEach((serviceProvided: any) => {
          servicesProvided[serviceProvided.control] =
            customerDetails?.servicesProvided[serviceProvided.control] ? true : false;
        });
        const industriesServedPatch: any = {};
        this.industriesServedOptions.forEach(industry => {
          industriesServedPatch[industry.control] =
            customerDetails?.industriesServed[industry.control] ? true : false;
        });
        const languageSpokenPatch: any = {};
        this.languageSpokenOptions.forEach(language => {
          languageSpokenPatch[language.control] =
            customerDetails?.languagesKnown[language.control] ? true : false;
        });
        const professionalDesignationsPatch: any = {};
        this.professionalDesignationsOptions.forEach(professionalDesignation => {
          professionalDesignationsPatch[professionalDesignation.control] =
            customerDetails?.professionalDesignations[professionalDesignation.control] ? true : false;
        });
        this.otherDetailsFormGroup.patchValue({
          hideEmailInRockstar: customerDetails?.rockstarSettings?.hideEmailInRockstar,
          hidePhoneNoInRockstar: customerDetails?.rockstarSettings?.hidePhoneNoInRockstar,
          serviceProvided: servicesProvided,
          industriesServed: industriesServedPatch,
          languageSpoken: languageSpokenPatch,
          professionalDesignations: professionalDesignationsPatch
        });
        if (!this.profileImageFromAws) {
          this.userService.getUserProfilePic(this.userEmail).then((profilePicDetails: any) => {
            this.logger.info('INFO: -> AddUserComponent -> this.userService.getUserProfilePic -> profilePicDetails', profilePicDetails);
            if (profilePicDetails.status) {
              this.userProfilePic = 'data:' + profilePicDetails?.data?.content_type + ';base64,' + profilePicDetails?.data?.image;
            }
          }).catch(error => {
            this.logger.error('ERROR: -> AddUserComponent -> this.userService.getUserProfilePic -> error', error);
            this.commonUtility.displayErrorToastWithTechDetails('Get Profile Image', error.error);
          });
        }
        this.ngxService.stopLoader('add-profile-loader');
      }).catch(error => {
        this.ngxService.stopLoader('add-profile-loader');
        this.logger.error('ERROR: -> AddUserComponent -> this.userService.getUserDetails -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails('Get User Details', error.error);
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get personalDetailsControl() {
    return this.personalDetailsFormGroup.controls;
  }

  get companyDetailsFormControl() {
    return this.companyDetailsFormGroup.controls;
  }

  stepChanged() {

  }

  onUpdateProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      const profileImage = event.target.files[0];
      formData.append('image', profileImage);
      formData.append('userEmail', this.userEmail);
      this.userService.updateUserProfileImage(formData).then((response: any) => {
        this.logger.info('INFO: -> AddUserComponent -> ' +
          'this.userService.updateUserProfileImage -> response', response);
        if (response) {
          this.commonUtility.displaySuccessToast('Upload Profile Image', response.message);
          this.profileImageFromAws = response.profile_image;
        } else {
          this.commonUtility.displayErrorToast('Upload Profile Image',
            'Please upload a valid image file. Supported image formats are JPG, JPEG and PNG.');
        }
      }).catch(error => {
        this.logger.error('ERROR: -> AddUserComponent -> this.userService.updateUserProfileImage -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails('Upload Profile Image', error.error);
      });
    }
  }

  updateUserDetails() {
    const title = this.userExists ? 'Edit Accountant Partner' : 'Add Accountant Partner';
    if (this.personalDetailsFormGroup.valid &&
      this.companyDetailsFormGroup.valid &&
      this.otherDetailsFormGroup.valid) {
      const requestData = {
        email: this.userEmail,
        otherDetails: this.otherDetailsFormGroup.value,
        personalDetails: this.personalDetailsFormGroup.value,
        companyDetails: this.companyDetailsFormGroup.value
      };
      if (this.userExists) {
        this.userService.editAccountantPartner(requestData).then((accountantDetails: any) => {
          this.logger.info('INFO: -> AddUserComponent -> this.userService.editAccountantPartner -> accountantDetails', accountantDetails);
          this.commonUtility.displaySuccessToast(title, accountantDetails.message);
          this.router.navigate(['/partners/all'], { queryParams: { search: this.userEmail }});
        }).catch(error => {
          this.logger.error('ERROR: -> AddUserComponent -> this.userService.editAccountantPartner -> error', error);
          this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
        });
      } else {
        this.userService.addAccountantPartner(requestData).then((accountantDetails: any) => {
          this.logger.info('INFO: -> AddUserComponent -> this.userService.addAccountantPartner -> accountantDetails', accountantDetails);
          this.commonUtility.displaySuccessToast(title, accountantDetails.message);
          this.router.navigate(['/partners/all'], { queryParams: { search: this.userEmail }});
        }).catch(error => {
          this.logger.error('ERROR: -> AddUserComponent -> this.userService.addAccountantPartner -> error', error);
          this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
        });
      }
    } else {
      this.personalDetailsFormGroup.markAllAsTouched();
      this.companyDetailsFormGroup.markAllAsTouched();
      this.otherDetailsFormGroup.markAllAsTouched();
      this.commonUtility.displayErrorToast(title, 'Please fill all the required fields.');
    }
  }

  getGeoDetails() {
    const title = 'Get Latitude/Longitude by ZipCode';
    const zipCode = this.companyDetailsFormGroup.value?.address?.zipCode;
    if (zipCode) {
      this.userService.getGeoDetails(zipCode).then((geoDetails: any) => {
        this.logger.info('INFO: -> AddUserComponent -> this.userService.getGeoDetails -> geoDetails', geoDetails);
        this.companyDetailsFormGroup.patchValue({
          address: {
            latitude: geoDetails.lat,
            longitude: geoDetails.lng
          }
        });
        this.commonUtility.displaySuccessToast(title, `Latitude/Longitude values updated successfully for ZipCode ${zipCode}.`);
      }).catch(error => {
        this.logger.error('ERROR: -> AddUserComponent -> this.userService.getGeoDetails -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(title, error?.error);
      });
    }
  }

}
