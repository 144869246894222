import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseComponent } from '@components/base/base.component';
import { InvalidLinkComponent } from '@components/invalid-link/invalid-link.component';
import { LoginComponent } from '@components/login/login.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';

import { FranchiseeResolverService } from '@services/franchisee-resolver.service';
import { RateLimitResolverService } from '@services/rate-limit-resolver.service';
import { DashboardComponent } from '@components/private/dashboard/dashboard.component';
import { FranchiseeComponent } from '@components/private/franchisee/franchisee.component';
import { AuthGuard } from '@app/helpers/auth.guard';
import { SideNavBarComponent } from '@components/private/side-nav-bar/side-nav-bar.component';
import { UserResolverService } from '@services/user-resolver.service';
import { LegacyListComponent } from '@components/private/accounts/legacy-list/legacy-list.component';
import { RegisterComponent } from '@components/private/accounts/register/register.component';
import { AllAccountsComponent } from '@components/private/accounts/all-accounts/all-accounts.component';
import { AccountsComponent } from '@components/private/accounts/accounts.component';
import { AccountDetailsComponent } from '@components/private/accounts/account-details/account-details.component';
import { AccountResolverService } from '@services/account-resolver.service';
import { UsersComponent } from '@components/private/users/users.component';
import { AllUsersComponent } from '@components/private/users/all-users/all-users.component';
import { AllSubscriptionOwnersComponent } from '@components/private/users/all-subscription-owners/all-subscription-owners.component';
import { UserDetailsComponent } from '@components/private/users/user-details/user-details.component';
import { PartnersComponent } from '@components/private/partners/partners.component';
import { AllPartnersComponent } from '@components/private/partners/all-partners/all-partners.component';
import { AddPartnerComponent } from '@components/private/partners/add-partner/add-partner.component';
import { SyncEngineComponent } from '@components/private/sync-engine/sync-engine.component';
import { NotificationsComponent } from '@components/private/notifications/notifications.component';
import { EventLogsComponent } from '@components/private/logs/event-logs/event-logs.component';
import { EmailLogsComponent } from '@components/private/logs/email-logs/email-logs.component';
import { LogsComponent } from '@components/private/logs/logs.component';
import { MaintenanceContentComponent } from '@components/private/content-manager/maintenance-content/maintenance-content.component';
import { LoginContentComponent } from '@components/private/content-manager/login-content/login-content.component';
import { ContentManagerComponent } from '@components/private/content-manager/content-manager.component';
import { MessagesComponent } from '@components/private/messages/messages.component';
import { MessagesReviewComponent } from '@components/private/messages-review/messages-review.component';

import { AllWhiteLabelPartnersComponent } from '@components/private/franchisee/all-white-label-partners/all-white-label-partners.component';
import { AddWhiteLabelPartnerComponent } from '@components/private/franchisee/add-white-label-partner/add-white-label-partner.component';
import { WhiteLabelPartnerDetailsComponent } from
    '@components/private/franchisee/white-label-partner-details/white-label-partner-details.component';
import { WhiteLabelPartnerResolverService } from '@services/white-label-partner-resolver.service';
import { ResellersComponent } from '@components/private/resellers/resellers.component';
import { AllResellersComponent } from '@components/private/resellers/all-resellers/all-resellers.component';
import { AddResellerComponent } from '@components/private/resellers/add-reseller/add-reseller.component';
import { ResellerDetailsComponent } from '@components/private/resellers/reseller-details/reseller-details.component';
import { ResellerResolverService } from '@services/reseller-resolver.service';
import { GoogleLoginResolverService } from '@services/google-login-resolver.service';
import { GoogleLoginComponent } from '@components/google-login/google-login.component';
import { DevelopersComponent } from '@components/private/developers/developers.component';
import { AllDevelopersComponent } from '@components/private/developers/all-developers/all-developers.component';
import { ChangeDevelopersAccessComponent } from
    './components/private/developers/change-developers-access/change-developers-access.component';
import { SecurityComponent } from './components/private/security/security.component';
import { InvitationsComponent } from './components/private/invitations/invitations.component';
import { SmLogsComponent } from './components/private/logs/sm-logs/sm-logs.component';
import { MonitoringViewComponent } from '@components/private/monitoring-view';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'invalid-link', component: InvalidLinkComponent, pathMatch: 'full'},
  { path: '',
    component: BaseComponent,
    resolve: {
      franchisee: FranchiseeResolverService
    },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        resolve: {
          rateLimitDetails: RateLimitResolverService
        }
      },
      {
        path: 'googleLogin',
        component: GoogleLoginComponent,
        resolve: {
          googleDetails: GoogleLoginResolverService
        }
      },
  ]
},
{ path: '',
    component: SideNavBarComponent,
    resolve: {
      franchisee: FranchiseeResolverService,
      userDetails: UserResolverService
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'all'
          },
          {
            path: 'all',
            component: AllAccountsComponent,
          },
          {
            path: 'all/:type',
            component: AllAccountsComponent,
          },
          {
            path: 'all/:type/:filter',
            component: AllAccountsComponent,
          },
          {
            path: 'register',
            component: RegisterComponent,
          },
          {
            path: 'legacy_accounts',
            component: LegacyListComponent,
          },
          {
            path: 'account-details/:id',
            component: AccountDetailsComponent,
            resolve: {
              accountDetails: AccountResolverService
            }
          }
      ]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'all'
          },
          {
            path: 'all',
            component: AllUsersComponent,
          },
          {
            path: 'subscription-owners',
            component: AllSubscriptionOwnersComponent,
          },
          {
            path: 'user-details/:id',
            component: UserDetailsComponent,
          }
        ]
      },
      {
        path: 'partner',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'accountant-partner',
            component: PartnersComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'all'
              },
              {
                path: 'all',
                component: AllPartnersComponent,
              },
              {
                path: 'add',
                component: AddPartnerComponent,
              },
              {
                path: 'view/:id',
                component: AddPartnerComponent,
              },
              {
                path: 'update-status/:id',
                component: AddPartnerComponent,
              },
              {
                path: 'edit/:id',
                component: AddPartnerComponent,
              }
            ]
          },
          {
            path: 'white-label-partner',
            component: FranchiseeComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'all'
              },
              {
                path: 'all',
                component: AllWhiteLabelPartnersComponent,
              },
              {
                path: 'add',
                component: AddWhiteLabelPartnerComponent,
                data: {
                  pageHeading: 'Add White Label Partner'
                },
              },
              {
                path: 'view/:id',
                component: WhiteLabelPartnerDetailsComponent,
                data: {
                  pageHeading: 'White Label Partner Details'
                },
                resolve: {
                  whiteLabelPartnerDetails: WhiteLabelPartnerResolverService
                }
              },
              {
                path: 'edit/:id',
                component: AddWhiteLabelPartnerComponent,
                data: {
                  pageHeading: 'Edit White Label Partner'
                },
                resolve: {
                  whiteLabelPartnerDetails: WhiteLabelPartnerResolverService
                }
              }
            ]
          },
          {
            path: 'reseller',
            component: ResellersComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'all'
              },
              {
                path: 'all',
                component: AllResellersComponent,
              },
              {
                path: 'add',
                component: AddResellerComponent,
                data: {
                  pageHeading: 'Add Reseller'
                }
              },
              {
                path: 'edit/:id',
                component: AddResellerComponent,
                data: {
                  pageHeading: 'Edit Reseller'
                },
                resolve: {
                  resellerDetails: ResellerResolverService
                }
              },
              {
                path: 'view/:id',
                component: ResellerDetailsComponent,
                data: {
                  pageHeading: 'Reseller Details'
                },
                resolve: {
                  resellerDetails: ResellerResolverService
                }
              }
            ]
          },
          {
            path: 'developer',
            component: DevelopersComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'all'
              },
              {
                path: 'all',
                component: AllDevelopersComponent,
              },
              {
                path: 'change-developer-access',
                component: ChangeDevelopersAccessComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'partners',
        component: PartnersComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'all'
          },
          {
            path: 'all',
            component: AllPartnersComponent,
          },
          {
            path: 'add',
            component: AddPartnerComponent,
          },
          {
            path: 'view/:id',
            component: AddPartnerComponent,
          },
          {
            path: 'update-status/:id',
            component: AddPartnerComponent,
          },
          {
            path: 'edit/:id',
            component: AddPartnerComponent,
          }
        ]
      },
      {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'event-logs'
          },
          {
            path: 'event-logs',
            component: EventLogsComponent,
          },
          {
            path: 'email-logs',
            component: EmailLogsComponent,
          },
          {
            path: 'service-manager-logs',
            component: SmLogsComponent,
          }
        ]
      },
      {
        path: 'content-manager',
        component: ContentManagerComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'login'
          },
          {
            path: 'login',
            component: LoginContentComponent,
          },
          {
            path: 'maintenance',
            component: MaintenanceContentComponent,
          }
        ]
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'invitations',
        component: InvitationsComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'messages-review',
        component: MessagesReviewComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'sync-engine',
        component: SyncEngineComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      },
      {
        path: 'monitoring',
        component: MonitoringViewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'security',
        component: SecurityComponent,
        canActivate: [AuthGuard],
        resolve: {
        }
      }
  ]
},
{
  path: '',
  component: BaseComponent,
  resolve: {
    franchisee: FranchiseeResolverService
  },
  children: [{
    path: '**',
    component: NotFoundComponent,
    resolve: {
      franchisee: FranchiseeResolverService
    }
  }]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
