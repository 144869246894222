import { environment } from '@env/environment';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { CookieService } from 'ngx-cookie-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DataTablesModule } from 'angular-datatables';
import { NgChartsModule } from 'ng2-charts';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS
} from '@angular-material-components/color-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

import { SafeHtmlPipe } from '@app/helpers/safe-html.pipe';
import { SortPipe } from '@app/helpers/sort.pipe';
import { FormatTimePipe } from '@app/helpers/format-time.pipe';
import { FormatIpAddressPipe } from '@app/helpers/format-ip-address.pipe';
import { JwtInterceptor } from '@app/helpers/jwt.interceptor';

import { BaseComponent } from '@components/base/base.component';
import { LoginComponent } from '@components/login/login.component';
import { DashboardComponent } from '@components/private/dashboard/dashboard.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { InvalidLinkComponent } from '@components/invalid-link/invalid-link.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { FranchiseeComponent } from '@components/private/franchisee/franchisee.component';
import { InnerHeaderComponent } from '@components/private/inner-header/inner-header.component';
import { InnerFooterComponent } from '@components/private/inner-footer/inner-footer.component';
import { SideNavBarComponent } from '@components/private/side-nav-bar/side-nav-bar.component';

import { RegisterComponent } from '@components/private/accounts/register/register.component';
import { AccountsComponent } from '@components/private/accounts/accounts.component';
import { AllAccountsComponent } from '@components/private/accounts/all-accounts/all-accounts.component';
import { LegacyListComponent } from '@components/private/accounts/legacy-list/legacy-list.component';
import { ExtendTrialComponent } from '@components/private/accounts/extend-trial/extend-trial.component';
import { AccountDetailsComponent } from '@components/private/accounts/account-details/account-details.component';

import { UsersComponent } from '@components/private/users/users.component';
import { AllUsersComponent } from '@components/private/users/all-users/all-users.component';
import {
  AllSubscriptionOwnersComponent
} from '@components/private/users/all-subscription-owners/all-subscription-owners.component';
import { UserDetailsComponent } from '@components/private/users/user-details/user-details.component';
import {
  ExtendPlaygroundTrialComponent
} from '@components/private/users/extend-playground-trial/extend-playground-trial.component';
import { PartnersComponent } from '@components/private/partners/partners.component';
import { AllPartnersComponent } from '@components/private/partners/all-partners/all-partners.component';
import { AddPartnerComponent } from '@components/private/partners/add-partner/add-partner.component';
import { AddUserComponent } from '@components/private/users/add-user/add-user.component';
import {
  ChangePartnerStatusComponent
} from '@components/private/partners/change-partner-status/change-partner-status.component';
import { ChangeUserTypeComponent } from '@components/private/users/change-user-type/change-user-type.component';
import { RockstarSettingsComponent } from '@components/private/partners/rockstar-settings/rockstar-settings.component';
import {
  DeveloperSettingsComponent
} from '@components/private/partners/developer-settings/developer-settings.component';
import { UserAccountsListComponent } from '@components/private/users/user-accounts-list/user-accounts-list.component';
import {
  UserNotificationsListComponent
} from '@components/private/users/user-notifications-list/user-notifications-list.component';
import {
  UserPlaygroundDetailsComponent
} from '@components/private/users/user-playground-details/user-playground-details.component';
import { SyncEngineComponent } from '@components/private/sync-engine/sync-engine.component';
import { NotificationsComponent } from '@components/private/notifications/notifications.component';
import { LogsComponent } from '@components/private/logs/logs.component';
import { EventLogsComponent } from '@components/private/logs/event-logs/event-logs.component';
import { EmailLogsComponent } from '@components/private/logs/email-logs/email-logs.component';
import {
  EmailLogStatusInfoComponent
} from '@components/private/logs/email-log-status-info/email-log-status-info.component';
import {
  EventLogCategoryInfoComponent
} from '@components/private/logs/event-log-category-info/event-log-category-info.component';
import { EventLogTypeInfoComponent } from '@components/private/logs/event-log-type-info/event-log-type-info.component';
import { ContentManagerComponent } from '@components/private/content-manager/content-manager.component';
import { LoginContentComponent } from '@components/private/content-manager/login-content/login-content.component';
import {
  MaintenanceContentComponent
} from '@components/private/content-manager/maintenance-content/maintenance-content.component';
import { MessagesComponent } from '@components/private/messages/messages.component';
import {
  AllWhiteLabelPartnersComponent
} from '@components/private/franchisee/all-white-label-partners/all-white-label-partners.component';
import { WhiteLabelPartnerDetailsComponent }
  from '@components/private/franchisee/white-label-partner-details/white-label-partner-details.component';
import { AddWhiteLabelPartnerComponent } from
    '@components/private/franchisee/add-white-label-partner/add-white-label-partner.component';
import { WhiteLabelPartnerAccountsComponent }
  from './components/private/franchisee/white-label-partner-accounts/white-label-partner-accounts.component';
import {
  ChangeBillingStatusComponent
} from './components/private/accounts/change-billing-status/change-billing-status.component';
import { ComposeMessageComponent } from './components/private/messages/compose-message/compose-message.component';
import { RockstarReviewComponent } from './components/private/partners/rockstar-review/rockstar-review.component';
import {
  ChangePricingLevelComponent
} from './components/private/users/change-pricing-level/change-pricing-level.component';
import { ResellersComponent } from './components/private/resellers/resellers.component';
import { AllResellersComponent } from './components/private/resellers/all-resellers/all-resellers.component';
import { AddResellerComponent } from './components/private/resellers/add-reseller/add-reseller.component';
import { ResellerDetailsComponent } from './components/private/resellers/reseller-details/reseller-details.component';
import { ResellerUserListComponent } from './components/private/users/reseller-user-list/reseller-user-list.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { DevelopersComponent } from './components/private/developers/developers.component';
import { AllDevelopersComponent } from './components/private/developers/all-developers/all-developers.component';
import { ChangeDevelopersAccessComponent }
  from './components/private/developers/change-developers-access/change-developers-access.component';
import { ResellerWidgetIdUniquenessCheckDirective } from './helpers/reseller-widgetId-uniqueness-check.directive';
import { ResellerUrlUniquenessCheckDirective } from './helpers/reseller-url-uniqueness-check.directive';
import {
  MultiFactorLoginPopupComponent
} from './components/multi-factor-login-popup/multi-factor-login-popup.component';
import { SecurityComponent } from './components/private/security/security.component';
import { MultiFactorDisablePopupComponent }
  from './components/private/security/multi-factor-disable-popup/multi-factor-disable-popup.component';
import { MultiFactorSetupPopupComponent }
  from './components/private/security/multi-factor-setup-popup/multi-factor-setup-popup.component';
import { MessagesReviewComponent } from './components/private/messages-review/messages-review.component';
import { InvitationsComponent } from './components/private/invitations/invitations.component';
import { SmLogsComponent } from './components/private/logs/sm-logs/sm-logs.component';
import { MonitoringViewComponent } from './components/private/monitoring-view';
import { AgGridModule } from '@ag-grid-community/angular';
import { CellWarningRendererComponent } from '@components/private/monitoring-view/renderers';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    AccountDetailsComponent,
    AccountsComponent,
    AddPartnerComponent,
    AddResellerComponent,
    AddUserComponent,
    AddWhiteLabelPartnerComponent,
    AllAccountsComponent,
    AllDevelopersComponent,
    AllPartnersComponent,
    AllResellersComponent,
    AllSubscriptionOwnersComponent,
    AllUsersComponent,
    AllWhiteLabelPartnersComponent,
    AppComponent,
    BaseComponent,
    CellWarningRendererComponent,
    ChangeBillingStatusComponent,
    ChangeDevelopersAccessComponent,
    ChangePartnerStatusComponent,
    ChangePricingLevelComponent,
    ChangeUserTypeComponent,
    ComposeMessageComponent,
    ContentManagerComponent,
    DashboardComponent,
    DevelopersComponent,
    DeveloperSettingsComponent,
    EmailLogsComponent,
    EmailLogStatusInfoComponent,
    EventLogCategoryInfoComponent,
    EventLogsComponent,
    EventLogTypeInfoComponent,
    ExtendPlaygroundTrialComponent,
    ExtendTrialComponent,
    FooterComponent,
    FormatTimePipe,
    FormatIpAddressPipe,
    FranchiseeComponent,
    GoogleLoginComponent,
    HeaderComponent,
    InnerFooterComponent,
    InnerHeaderComponent,
    InvalidLinkComponent,
    LegacyListComponent,
    LoginComponent,
    LoginContentComponent,
    LogsComponent,
    MaintenanceContentComponent,
    MessagesComponent,
    MonitoringViewComponent,
    MultiFactorLoginPopupComponent,
    NotFoundComponent,
    NotificationsComponent,
    PartnersComponent,
    RegisterComponent,
    ResellerDetailsComponent,
    ResellersComponent,
    ResellerUrlUniquenessCheckDirective,
    ResellerUserListComponent,
    ResellerWidgetIdUniquenessCheckDirective,
    RockstarReviewComponent,
    RockstarSettingsComponent,
    SafeHtmlPipe,
    SideNavBarComponent,
    SortPipe,
    SyncEngineComponent,
    UserAccountsListComponent,
    UserDetailsComponent,
    UserNotificationsListComponent,
    UserPlaygroundDetailsComponent,
    UsersComponent,
    WhiteLabelPartnerAccountsComponent,
    WhiteLabelPartnerDetailsComponent,
    SecurityComponent,
    MultiFactorDisablePopupComponent,
    MultiFactorSetupPopupComponent,
    MessagesReviewComponent,
    InvitationsComponent,
    SmLogsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgGridModule,
    GoogleMapsModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FontAwesomeModule,
    MatSidenavModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatMomentDateModule,
    ClipboardModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    NgxIntlTelInputModule,
    NgxMatColorPickerModule,
    NgbModule,
    NgChartsModule,
    NgxPaginationModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: environment.disableConsoleLogging,
      serverLoggingUrl: '/frontEndLogs',
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: !environment.disableConsoleLogging
    }),
    NgxDaterangepickerMd.forRoot(),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    ShowHidePasswordModule,
    ToastrModule.forRoot({
      closeButton: true,
      disableTimeOut: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      tapToDismiss: false
    }),
    NgxSummernoteModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    CookieService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
    {provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {position: 'above'}}
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
