<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 register-account-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 register-account-box">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-md-12 register-padding">
                        <form *ngIf="!submitted" class="check-user-form"
                            [ngClass]="{'was-validated': (checkUserForm.email.dirty || checkUserForm.email.touched)}"
                            [formGroup]="emailCheckForm" (ngSubmit)="checkEmail()" novalidate>
                            <div class="mt-2">
                                <label for="email">Email address <span class="required">*</span></label>
                            </div>
                            <div class="mb-3 ps-2">
                                <input type="email" name="email" class="form-control" formControlName="email" id="email"
                                    placeholder="name@example.com">
                                <div *ngIf="checkUserForm.email.invalid && (checkUserForm.email.dirty || checkUserForm.email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="checkUserForm.email.errors.required">Email address is required.</div>
                                    <div *ngIf="checkUserForm.email.errors.email">Please enter a valid email address.
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 ps-2">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!emailCheckForm.valid">OK</button>
                            </div>
                        </form>
                        <form *ngIf="submitted" [formGroup]="registrationForm" novalidate
                            (ngSubmit)="registerAccount()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="email">Email address <span class="required">*</span></label>
                                        <input type="email" name="email" class="form-control" formControlName="email"
                                            id="email" placeholder="name@example.com">
                                        <div *ngIf="registerAccountForm.email.invalid && (registerAccountForm.email.dirty || registerAccountForm.email.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.email.errors.required">Email address is
                                                required.
                                            </div>
                                            <div *ngIf="registerAccountForm.email.errors.email">Please enter a valid
                                                email
                                                address.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="firstname">First Name <span class="required">*</span></label>
                                        <input type="text" name="firstname" class="form-control"
                                            formControlName="firstname" id="email" placeholder="">
                                        <div *ngIf="registerAccountForm.firstname.invalid && (registerAccountForm.firstname.dirty || registerAccountForm.firstname.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.firstname.errors.required">First Name is
                                                required.
                                            </div>
                                            <div *ngIf="registerAccountForm.firstname.errors.maxLength">First name should
                                                not be greater than 75 characters.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lastname">Last Name <span class="required">*</span></label>
                                        <input type="text" name="lastname" class="form-control"
                                            formControlName="lastname" id="email" placeholder="">
                                        <div *ngIf="registerAccountForm.lastname.invalid && (registerAccountForm.lastname.dirty || registerAccountForm.lastname.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.lastname.errors.required">Last Name is
                                                required.
                                            </div>
                                            <div *ngIf="registerAccountForm.lastname.errors.maxLength">Last name should
                                                not be greater than 75 characters.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="companyName">Company Name <span class="required">*</span></label>
                                        <input type="text" name="companyName" class="form-control"
                                            formControlName="companyName" id="companyName" placeholder="">
                                        <div *ngIf="registerAccountForm.companyName.invalid && (registerAccountForm.companyName.dirty || registerAccountForm.lastname.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.companyName.errors.required">Company Name is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="planName">Plan Name <span class="required">*</span></label>
                                        <select type="select" name="planName" class="form-select"
                                            formControlName="planName" id="email" placeholder="">
                                            <option value="">Select Plan Name</option>
                                            <option *ngFor="let item of plansList" [value]="item.value">{{item.label}}
                                            </option>
                                        </select>
                                        <div *ngIf="registerAccountForm.planName.invalid && (registerAccountForm.planName.dirty || registerAccountForm.firstname.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.planName.errors.required">Plan Name is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="type">Type <span class="required">*</span></label>
                                        <select type="select" name="type" class="form-select" formControlName="type"
                                            id="type" placeholder="">
                                            <option value="">Select Type</option>
                                            <option *ngFor="let item of statusList" [value]="item.value">{{item.label}}
                                            </option>
                                        </select>
                                        <div *ngIf="registerAccountForm.type.invalid && (registerAccountForm.type.dirty || registerAccountForm.type.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.type.errors.required">Type is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="convertedFrom">Converted From <span
                                                class="required">*</span></label>
                                        <select type="select" name="convertedFrom" class="form-select"
                                            formControlName="convertedFrom" id="convertedFrom" placeholder="">
                                            <option value="">Select Converted From</option>
                                            <option *ngFor="let item of convertedFromList" [value]="item.value">
                                                {{item.name}}</option>
                                        </select>
                                        <div *ngIf="registerAccountForm.convertedFrom.invalid && (registerAccountForm.convertedFrom.dirty || registerAccountForm.convertedFrom.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.convertedFrom.errors.required">Converted
                                                From is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="franchisee">Franchisee <span class="required">*</span></label>
                                        <select type="franchisee" name="franchisee" class="form-select"
                                            formControlName="franchisee" id="franchisee" placeholder="">
                                            <option value="">Select Franchisee</option>
                                            <option *ngFor="let item of franchiseeList" [value]="item.value">
                                                {{item.name}}</option>
                                        </select>
                                        <div *ngIf="registerAccountForm.franchisee.invalid && (registerAccountForm.franchisee.dirty || registerAccountForm.franchisee.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="registerAccountForm.franchisee.errors.required">Franchisee is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="bill" class="me-2">Create Subscription</label>
                                    <mat-slide-toggle [checked]="false" formControlName="billed" [disabled]="true">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-6">
                                    <button type="submit" class="btn btn-primary float-end"
                                        [disabled]="!registrationForm.valid">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
