import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[appResellerUrlUniquenessCheck]',
    providers: [
        {provide: NG_ASYNC_VALIDATORS, useExisting: ResellerUrlUniquenessCheckDirective, multi: true}
    ]
})
export class ResellerUrlUniquenessCheckDirective implements AsyncValidator {
    api: string = location.origin;
    constructor(
        private http: HttpClient
    ) {
        if (this.api.includes('localhost') ||
            this.api.includes('127.0.0.1')) {
            this.api = environment.CAT_URL;
        }
    }

    validate(control): Observable<ValidationErrors | null> {
        const obs = this.http.get<boolean>(this.api + '/check-reseller-url-uniqueness?url=' + control.value +
        '&code=' + control?._parent?.value?.code)
            .pipe(
                map((isUsed) =>
                    // null no error, object for error
                     isUsed ? null : {
                        urlValidator: 'URL identifier already used.'
                    }
                )
            );
        return obs;
    }
}

