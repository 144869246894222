import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { WhiteLabelPartner } from '@app/models/white-label-partner';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { WhiteLabelPartnerService } from '@app/services/white-label-partner.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { ThemeService } from '@app/services/theme.service';
import { Color } from '@angular-material-components/color-picker';
import { AuthenticationService } from '@app/services/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-white-label-partner',
  templateUrl: './add-white-label-partner.component.html',
  styleUrls: ['./add-white-label-partner.component.css']
})
export class AddWhiteLabelPartnerComponent implements OnInit, OnDestroy {
  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '400px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['codeview', 'help']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '44', '56', '64', '76', '84', '96'],
    fontNames: ['Arial', 'Times New Roman', 'Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'MangCau',
      'BayBuomHep', 'BaiSau', 'BaiHoc', 'CoDien', 'BucThu', 'KeChuyen', 'MayChu', 'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    // eslint-disable-next-line max-len
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };
  title: string;
  pageHeading: string;

  companyDetailsFormGroup: UntypedFormGroup;
  featureConfigFormGroup: UntypedFormGroup;
  themeConfigFormGroup: UntypedFormGroup;
  templatesFormGroup: UntypedFormGroup;
  setupFormGroup: UntypedFormGroup;

  WhiteLabelPartnerDetails: WhiteLabelPartner;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  loginWithFacebook: boolean;
  loginWithGoogle: boolean;
  loginWithLinkedIn: boolean;
  getStartedHelpLink: boolean;

  showContactUsLink: boolean;
  showHelpLink: boolean;
  showAccountLink: boolean;
  showMessages: boolean;
  showNotifications: boolean;
  showPlayground: boolean;
  showMultiEntityReport: boolean;
  showAddNewClient: boolean;

  enableAccountantDashboard: boolean;
  enableClientDashboard: boolean;

  showMFASetup: boolean;
  showCompanyDetails: boolean;
  showPersonalDetails: boolean;
  urlReg = /^https:\/\/+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  hideSecrets = true;
  whiteLabelPartnerTemplateAccess = false;
  whiteLabelPartnerSetupAccess = false;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private whiteLabelPartnerService: WhiteLabelPartnerService,
    private commonUtility: CommonUtilityService,
    private themeService: ThemeService,
    private ngxService: NgxUiLoaderService
  ) {
    this.pageHeading = 'Add White Label Partners';
    this.route.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.WhiteLabelPartnerDetails = data?.whiteLabelPartnerDetails;
        this.pageHeading = data?.pageHeading;
    });
  }

  ngOnInit(): void {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.isAccessOfWLPSetupAccess) {
      this.whiteLabelPartnerSetupAccess = true;
    }
    if (currentUser && currentUser.isAccessOfWLPTempSetting) {
      this.whiteLabelPartnerTemplateAccess = true;
    }
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
    });

    if (!this.WhiteLabelPartnerDetails) {
      this.WhiteLabelPartnerDetails = new WhiteLabelPartner();
      this.WhiteLabelPartnerDetails.backgroundColor = {red: 247, green: 201, blue: 7, alpha: 0};
      this.WhiteLabelPartnerDetails.textColor = {red: 54, green: 54, blue: 54, alpha: 0};
      this.WhiteLabelPartnerDetails.primaryColor = {red: 247, green: 201, blue: 7, alpha: 0};
      this.WhiteLabelPartnerDetails.primaryButtonTextColor = {red: 0, green: 0, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.primaryButtonHoverBackgroundColor = {red: 252, green: 207, blue: 25, alpha: 0};
      this.WhiteLabelPartnerDetails.primaryButtonHoverTextColor = {red: 0, green: 0, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.primaryButtonBackgroundColor = {red: 252, green: 207, blue: 25, alpha: 0};
      this.WhiteLabelPartnerDetails.secondaryButtonBackgroundColor = {red: 255, green: 255, blue: 255, alpha: 0};
      this.WhiteLabelPartnerDetails.secondaryButtonHoverBackgroundColor = {red: 230, green: 230, blue: 230, alpha: 0};
      this.WhiteLabelPartnerDetails.secondaryButtonHoverTextColor = {red: 0, green: 0, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.secondaryButtonTextColor = {red: 0, green: 0, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.loginButtonBackgroundColor = {red: 54, green: 54, blue: 54, alpha: 0};
      this.WhiteLabelPartnerDetails.loginButtonTextColor = {red: 254, green: 203, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.loginButtonHoverBackgroundColor = {red: 54, green: 54, blue: 54, alpha: 0};
      this.WhiteLabelPartnerDetails.loginButtonHoverTextColor = {red: 254, green: 203, blue: 0, alpha: 0};
      this.WhiteLabelPartnerDetails.footer = '';
      this.WhiteLabelPartnerDetails.header = '';
      this.WhiteLabelPartnerDetails.login = '<div class="text-center"><img class="thumb-image loaded" alt="Sleeter Savvy Startup | ' +
        'Cloud Accounting" src="https://dev.custombooks.com/img/Sleeter+Savvy+Startup+_+Cloud+Accounting.png" ' +
        'style="top: -0.0266667%; left: 0%; width: 100%; height: 100%;" data-image-resolution="1500w"></div>';
      this.WhiteLabelPartnerDetails.warningMessage = '<strong style=\'color: inherit !important;\'>Attention!</strong> ' +
        'CustomBooks will be unavailable for scheduled maintenance starting 8pm PST on Friday (Mar. 3)' +
        ' through 2am PST on Saturday (Mar. 4).<br> The application will be ' +
        'unavailable at this time. Thank you for your patience.';
    }
    this.companyDetailsFormGroup = this.formBuilder.group({
      CompanyName: [this.WhiteLabelPartnerDetails?.companyName, Validators.required],
      FranchiseAdminEmail: [this.WhiteLabelPartnerDetails?.adminEmail, [Validators.required, Validators.email]],
      CompanyURL: [this.WhiteLabelPartnerDetails?.companyURL, Validators.pattern(this.urlReg)],
      ContactNumber: [this.WhiteLabelPartnerDetails?.contactNumber],
      ContactNumberExt: [this.WhiteLabelPartnerDetails?.contactNumberExt],
      WorkingHours: [this.WhiteLabelPartnerDetails?.workingHours],
      InquireNumber: [this.WhiteLabelPartnerDetails?.inquireNumber],
      HelpLineNumber: [this.WhiteLabelPartnerDetails?.helpLineNumber],
      SupportEmail: [this.WhiteLabelPartnerDetails?.supportEmail, Validators.email],
      helpEmail: [this.WhiteLabelPartnerDetails?.helpEmail, Validators.email],
      PhysicalAddress: this.formBuilder.group({
        AddressLine1: [this.WhiteLabelPartnerDetails?.physicalAddress?.addressLine1],
        AddressLine2: [this.WhiteLabelPartnerDetails?.physicalAddress?.addressLine2],
        City: [this.WhiteLabelPartnerDetails?.physicalAddress?.city],
        State: [this.WhiteLabelPartnerDetails?.physicalAddress?.state],
        Zip: [this.WhiteLabelPartnerDetails?.physicalAddress?.zipCode]
      })
    });
    this.featureConfigFormGroup = this.formBuilder.group({
      /* eslint-disable camelcase */
      login_config: this.formBuilder.group({
        get_started_help_link: [this.WhiteLabelPartnerDetails?.showGetStartedHelpLink]
      }),
      GetStartedHelpLink: [this.WhiteLabelPartnerDetails?.getStartedHelpLink],
      profile_config: this.formBuilder.group({
        personal_details: [this.WhiteLabelPartnerDetails?.showPersonalDetails || true],
        company_details: [this.WhiteLabelPartnerDetails?.showCompanyDetails],
        mfa_setup: [this.WhiteLabelPartnerDetails?.showMFASetup]
      }),
      dashboard_config: this.formBuilder.group({
        add_client: [this.WhiteLabelPartnerDetails?.showAddNewClient],
        multi_entity_reports: [this.WhiteLabelPartnerDetails?.showMultiEntityReport],
        playground: [this.WhiteLabelPartnerDetails?.showPlayground]
      }),
      navbar_config: this.formBuilder.group({
        notification: [this.WhiteLabelPartnerDetails?.showNotificationMenu],
        inbox: [this.WhiteLabelPartnerDetails?.showInboxMenu],
        zoho_account: [this.WhiteLabelPartnerDetails?.showZohoAccount],
        contact_us_link: [this.WhiteLabelPartnerDetails?.showContactUsLink],
        help_link: [this.WhiteLabelPartnerDetails?.showHelpLink]
      }),
      /* eslint-enable camelcase */
      ContactUsLink: [this.WhiteLabelPartnerDetails?.contactUsLink],
      ZOHO_SUB_CLIENT_PORTAL_LINK: [this.WhiteLabelPartnerDetails?.zohoSubCustomerPortalLink],
      samlUrl: [this.WhiteLabelPartnerDetails?.samlUrl],
      samlCert: [this.WhiteLabelPartnerDetails?.samlCert],
      samlKey: [this.WhiteLabelPartnerDetails?.samlKey],
      ZOHO_DESK_SETUP: this.formBuilder.group({
        REMOTE_AUTH_KEY: [this.WhiteLabelPartnerDetails?.zohoHelpDeskKey],
        REMOTE_AUTH_URL: [this.WhiteLabelPartnerDetails?.zohoHelpDeskURL]
      })
    });

    /* when change value of get started link change validation */
    this.featureConfigFormGroup.get('login_config.get_started_help_link').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.featureConfigFormGroup.get('GetStartedHelpLink').setValidators(changes);
        this.featureConfigFormGroup.get('GetStartedHelpLink').updateValueAndValidity();
    });
    // for edit franchisee if show get started help link is enabled
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.showGetStartedHelpLink) {
      const changes = [Validators.required, Validators.pattern(this.urlReg)];
      this.featureConfigFormGroup.get('GetStartedHelpLink').setValidators(changes);
      this.featureConfigFormGroup.get('GetStartedHelpLink').updateValueAndValidity();
    }


    this.featureConfigFormGroup.get('navbar_config.contact_us_link').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.featureConfigFormGroup.get('ContactUsLink').setValidators(changes);
        this.featureConfigFormGroup.get('ContactUsLink').updateValueAndValidity();
    });

    this.featureConfigFormGroup.get('navbar_config.help_link').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.featureConfigFormGroup.get('ZOHO_DESK_SETUP.REMOTE_AUTH_KEY').setValidators(changes);
        this.featureConfigFormGroup.get('ZOHO_DESK_SETUP.REMOTE_AUTH_URL').setValidators(changes);
        this.featureConfigFormGroup.get('ZOHO_DESK_SETUP.REMOTE_AUTH_KEY').updateValueAndValidity();
        this.featureConfigFormGroup.get('ZOHO_DESK_SETUP.REMOTE_AUTH_URL').updateValueAndValidity();
    });

    this.featureConfigFormGroup.get('navbar_config.zoho_account').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.featureConfigFormGroup.get('ZOHO_SUB_CLIENT_PORTAL_LINK').setValidators(changes);
        this.featureConfigFormGroup.get('samlUrl').setValidators(changes);
        this.featureConfigFormGroup.get('samlCert').setValidators(changes);
        this.featureConfigFormGroup.get('samlKey').setValidators(changes);
        this.featureConfigFormGroup.get('ZOHO_SUB_CLIENT_PORTAL_LINK').updateValueAndValidity();
        this.featureConfigFormGroup.get('samlUrl').updateValueAndValidity();
        this.featureConfigFormGroup.get('samlCert').updateValueAndValidity();
        this.featureConfigFormGroup.get('samlKey').updateValueAndValidity();
    });

    this.themeConfigFormGroup = this.formBuilder.group(
      {
        buttons: this.formBuilder.group(
        {
          primary: this.formBuilder.group({
            backgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.alpha
            ), Validators.required],
            textColor: [new Color(
              this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.red,
              this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.green,
              this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.blue,
              this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.alpha
            ), Validators.required],
            hoverBackgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.alpha
            ), Validators.required],
            hoverTextColor: [new Color(
              this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.red,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.green,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.blue,
              this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.alpha
            ), Validators.required]
          }),
          secondary: this.formBuilder.group({
            backgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.alpha
            ), Validators.required],
            textColor: [new Color(
              this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.red,
              this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.green,
              this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.blue,
              this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.alpha
            ), Validators.required],
            hoverBackgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.alpha
            ), Validators.required],
            hoverTextColor: [new Color(
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.red,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.green,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.blue,
              this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.alpha
            ), Validators.required]
          }),
          login: this.formBuilder.group({
            backgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.alpha
            ), Validators.required],
            textColor: [new Color(
              this.WhiteLabelPartnerDetails?.loginButtonTextColor?.red,
              this.WhiteLabelPartnerDetails?.loginButtonTextColor?.green,
              this.WhiteLabelPartnerDetails?.loginButtonTextColor?.blue,
              this.WhiteLabelPartnerDetails?.loginButtonTextColor?.alpha
              ), Validators.required],
            hoverBackgroundColor: [new Color(
              this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.red,
              this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.green,
              this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.blue,
              this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.alpha
            ), Validators.required],
            hoverTextColor: [new Color(
              this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.red,
              this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.green,
              this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.blue,
              this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.alpha
            ), Validators.required]
          }),
      }),
      PrimaryColor: [new Color(
        this.WhiteLabelPartnerDetails?.primaryColor?.red,
        this.WhiteLabelPartnerDetails?.primaryColor?.green,
        this.WhiteLabelPartnerDetails?.primaryColor?.blue,
        this.WhiteLabelPartnerDetails?.primaryColor?.alpha
        ), Validators.required],
      TextColor: [new Color(this.WhiteLabelPartnerDetails?.textColor?.red,
        this.WhiteLabelPartnerDetails?.textColor?.green,
        this.WhiteLabelPartnerDetails?.textColor?.blue,
        this.WhiteLabelPartnerDetails?.textColor?.alpha
        ), Validators.required],
      BackgroundColor: [new Color(this.WhiteLabelPartnerDetails?.backgroundColor?.red,
        this.WhiteLabelPartnerDetails?.backgroundColor?.green,
        this.WhiteLabelPartnerDetails?.backgroundColor?.blue,
        this.WhiteLabelPartnerDetails?.backgroundColor?.alpha
        ), Validators.required]

    });

    this.themeConfigFormGroup.get('buttons.primary.backgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-primary-btn-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.primaryButtonBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-primary-btn-background-color': new Color(
          this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.primaryButtonBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.primary.textColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-primary-btn-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.primaryButtonTextColor) {
      this.themeService.setTheme({
        'white-label-partner-primary-btn-text-color': new Color(
          this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.red,
          this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.green,
          this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.blue,
          this.WhiteLabelPartnerDetails?.primaryButtonTextColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.primary.hoverBackgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-primary-btn-hover-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.primaryButtonHoverBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-primary-btn-hover-background-color': new Color(
          this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.primary.hoverTextColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-primary-btn-hover-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.primaryButtonHoverTextColor) {
      this.themeService.setTheme({
        'white-label-partner-primary-btn-hover-text-color': new Color(
          this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.red,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.green,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.blue,
          this.WhiteLabelPartnerDetails?.primaryButtonHoverTextColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.secondary.backgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-secondary-btn-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.secondaryButtonBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-secondary-btn-background-color': new Color(
          this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.secondaryButtonBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.secondary.textColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-secondary-btn-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.secondaryButtonTextColor) {
      this.themeService.setTheme({
        'white-label-partner-secondary-btn-text-color': new Color(
          this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.red,
          this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.green,
          this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.blue,
          this.WhiteLabelPartnerDetails?.secondaryButtonTextColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.secondary.hoverBackgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-secondary-btn-hover-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.secondaryButtonHoverBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-secondary-btn-hover-background-color': new Color(
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.secondary.hoverTextColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-secondary-btn-hover-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.secondaryButtonHoverTextColor) {
      this.themeService.setTheme({
        'white-label-partner-secondary-btn-hover-text-color': new Color(
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.red,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.green,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.blue,
          this.WhiteLabelPartnerDetails?.secondaryButtonHoverTextColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.login.backgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-login-btn-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginButtonBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-login-btn-background-color': new Color(
          this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.loginButtonBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.login.textColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-login-btn-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginButtonTextColor) {
      this.themeService.setTheme({
        'white-label-partner-login-btn-text-color': new Color(
          this.WhiteLabelPartnerDetails?.loginButtonTextColor?.red,
          this.WhiteLabelPartnerDetails?.loginButtonTextColor?.green,
          this.WhiteLabelPartnerDetails?.loginButtonTextColor?.blue,
          this.WhiteLabelPartnerDetails?.loginButtonTextColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.login.hoverBackgroundColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-login-btn-hover-background-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginButtonHoverBackgroundColor) {
      this.themeService.setTheme({
        'white-label-partner-login-btn-hover-background-color': new Color(
          this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.red,
          this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.green,
          this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.blue,
          this.WhiteLabelPartnerDetails?.loginButtonHoverBackgroundColor?.alpha
        )
      });
    }

    this.themeConfigFormGroup.get('buttons.login.hoverTextColor').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.themeService.setTheme({
          'white-label-partner-login-btn-hover-text-color': '#' + val.hex
        });
    });
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginButtonHoverTextColor) {
      this.themeService.setTheme({
        'white-label-partner-login-btn-hover-text-color': new Color(
          this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.red,
          this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.green,
          this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.blue,
          this.WhiteLabelPartnerDetails?.loginButtonHoverTextColor?.alpha
        )
      });
    }

    this.templatesFormGroup = this.formBuilder.group({
      header: [this.WhiteLabelPartnerDetails?.header],
      footer: [this.WhiteLabelPartnerDetails?.footer],
      editableLogin: [this.WhiteLabelPartnerDetails?.login],
      maintenanceMessage: [this.WhiteLabelPartnerDetails?.warningMessage],
      downtimeMessage: [],
      warningOn: []
    });

    /* eslint-disable camelcase */
    this.setupFormGroup = this.formBuilder.group({
      login_config: this.formBuilder.group({
        login_with_facebook: [this.WhiteLabelPartnerDetails?.loginWithFacebook],
        login_with_linkedin: [this.WhiteLabelPartnerDetails?.loginWithLinkedIn],
        login_with_google: [this.WhiteLabelPartnerDetails?.loginWithGoogle],
      }),
      google_keys: this.formBuilder.group({
        ClientID: [this.WhiteLabelPartnerDetails?.googleKey?.clientId],
        ClientSecret: [this.WhiteLabelPartnerDetails?.googleKey?.clientSecret],
        RedirectURL: [this.WhiteLabelPartnerDetails?.googleKey?.redirectURL]
      }),
      linkedin_key: this.formBuilder.group({
        ClientID: [this.WhiteLabelPartnerDetails?.linkedInKey?.clientId],
        ClientSecret: [this.WhiteLabelPartnerDetails?.linkedInKey?.clientSecret],
        CSRFString: [this.WhiteLabelPartnerDetails?.linkedInKey?.csrfString],
        RedirectURL: [this.WhiteLabelPartnerDetails?.linkedInKey?.redirectURL]
      }),
      facebook_key: this.formBuilder.group({
        appId: [this.WhiteLabelPartnerDetails?.facebookKey?.appId],
        appSecret: [this.WhiteLabelPartnerDetails?.facebookKey?.appSecret],
        apiVersion: [this.WhiteLabelPartnerDetails?.facebookKey?.apiVersion],
        RedirectURL: [this.WhiteLabelPartnerDetails?.facebookKey?.redirectURL]
      }),
      /* eslint-enable camelcase */
      enableAccountantDashboard: [],
      AccountantDashboardURL: [this.WhiteLabelPartnerDetails?.accountantDashboardURL],
      FranchiseePAYURL: [this.WhiteLabelPartnerDetails?.payProjectURL],
      FranchiseeINTURL: [this.WhiteLabelPartnerDetails?.intacsProjectURL],
      enableClientDashboard: [],
      ClientDashboardURL: [this.WhiteLabelPartnerDetails?.clientDashboardURL]
    });

    /* when change value of login with facebook change validation*/
    this.setupFormGroup.get('login_config.login_with_facebook').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        let changes = [];
        if (val) {
          changes = [Validators.required];
        }
        this.setupFormGroup.get('facebook_key.appId').setValidators(changes);
        this.setupFormGroup.get('facebook_key.appSecret').setValidators(changes);
        this.setupFormGroup.get('facebook_key.apiVersion').setValidators(changes);
        this.setupFormGroup.get('facebook_key.RedirectURL').setValidators(changes);

        this.setupFormGroup.get('facebook_key.appId').updateValueAndValidity();
        this.setupFormGroup.get('facebook_key.appSecret').updateValueAndValidity();
        this.setupFormGroup.get('facebook_key.apiVersion').updateValueAndValidity();
        this.setupFormGroup.get('facebook_key.RedirectURL').updateValueAndValidity();
    });
    // for edit franchisee if login with facebook is enabled
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginWithFacebook) {
      const changes = [Validators.required];
      this.setupFormGroup.get('facebook_key.appId').setValidators(changes);
      this.setupFormGroup.get('facebook_key.appSecret').setValidators(changes);
      this.setupFormGroup.get('facebook_key.apiVersion').setValidators(changes);
      this.setupFormGroup.get('facebook_key.RedirectURL').setValidators(changes);

      this.setupFormGroup.get('facebook_key.appId').updateValueAndValidity();
      this.setupFormGroup.get('facebook_key.appSecret').updateValueAndValidity();
      this.setupFormGroup.get('facebook_key.apiVersion').updateValueAndValidity();
      this.setupFormGroup.get('facebook_key.RedirectURL').updateValueAndValidity();
    }

    /* when change value of login with google change validation */
    this.setupFormGroup.get('login_config.login_with_google').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.setupFormGroup.get('google_keys.ClientID').setValidators(changes);
        this.setupFormGroup.get('google_keys.ClientSecret').setValidators(changes);
        this.setupFormGroup.get('google_keys.RedirectURL').setValidators(changes);
        this.setupFormGroup.get('google_keys.ClientID').updateValueAndValidity();
        this.setupFormGroup.get('google_keys.ClientSecret').updateValueAndValidity();
        this.setupFormGroup.get('google_keys.RedirectURL').updateValueAndValidity();
    });
    // for edit franchisee if login with google is enabled
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginWithGoogle) {
      const changes = [Validators.required];
      this.setupFormGroup.get('google_keys.ClientID').setValidators(changes);
      this.setupFormGroup.get('google_keys.ClientSecret').setValidators(changes);
      this.setupFormGroup.get('google_keys.RedirectURL').setValidators(changes);

      this.setupFormGroup.get('google_keys.ClientID').updateValueAndValidity();
      this.setupFormGroup.get('google_keys.ClientSecret').updateValueAndValidity();
      this.setupFormGroup.get('google_keys.RedirectURL').updateValueAndValidity();
    }

    /* when change value of login with linkedIn change validation */
    this.setupFormGroup.get('login_config.login_with_linkedin').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const changes = val ? [Validators.required] : [];
        this.setupFormGroup.get('linkedin_key.ClientID').setValidators(changes);
        this.setupFormGroup.get('linkedin_key.CSRFString').setValidators(changes);
        this.setupFormGroup.get('linkedin_key.ClientSecret').setValidators(changes);
        this.setupFormGroup.get('linkedin_key.RedirectURL').setValidators(changes);
        this.setupFormGroup.get('linkedin_key.ClientID').updateValueAndValidity();
        this.setupFormGroup.get('linkedin_key.ClientSecret').updateValueAndValidity();
        this.setupFormGroup.get('linkedin_key.CSRFString').updateValueAndValidity();
        this.setupFormGroup.get('linkedin_key.RedirectURL').updateValueAndValidity();
    });
    // for edit franchisee if login with google is enabled
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.loginWithLinkedIn) {
      const changes = [Validators.required];
      this.setupFormGroup.get('linkedin_key.ClientID').setValidators(changes);
      this.setupFormGroup.get('linkedin_key.CSRFString').setValidators(changes);
      this.setupFormGroup.get('linkedin_key.ClientSecret').setValidators(changes);
      this.setupFormGroup.get('linkedin_key.RedirectURL').setValidators(changes);

      this.setupFormGroup.get('linkedin_key.ClientID').updateValueAndValidity();
      this.setupFormGroup.get('linkedin_key.ClientSecret').updateValueAndValidity();
      this.setupFormGroup.get('linkedin_key.CSRFString').updateValueAndValidity();
      this.setupFormGroup.get('linkedin_key.RedirectURL').updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get companyDetailsForm(){
    return this.companyDetailsFormGroup.controls;
  }

  get setupForm(){
    return this.setupFormGroup.controls;
  }

  get templatesForm(){
    return this.templatesFormGroup.controls;
  }

  get featureConfigForm() {
    return this.featureConfigFormGroup.controls;
  }

  get themeConfigForm() {
    return this.themeConfigFormGroup.controls;
  }

  saveWhiteLabelPartnerDetails() {
    this.ngxService.start();
    const whiteLabelPartner = {
      CompanyName: this.companyDetailsForm.CompanyName.value,
      FranchiseAdminEmail: this.companyDetailsForm.FranchiseAdminEmail.value,
      CompanyURL: this.companyDetailsForm.CompanyURL.value,
      ContactNumber: this.companyDetailsForm.ContactNumber.value?.internationalNumber || '',
      ContactNumberExt: this.companyDetailsForm.ContactNumberExt.value,
      WorkingHours: this.companyDetailsForm.WorkingHours.value,
      InquireNumber: this.companyDetailsForm.InquireNumber.value?.internationalNumber || '',
      HelpLineNumber: this.companyDetailsForm.HelpLineNumber.value?.internationalNumber || '',
      SupportEmail: this.companyDetailsForm.SupportEmail.value,
      helpEmail: this.companyDetailsForm.helpEmail.value,
      PhysicalAddress: this.companyDetailsForm.PhysicalAddress.value,
      ContactURL: this.featureConfigForm.ContactUsLink.value,
      GetStartedHelpLink: this.featureConfigForm.GetStartedHelpLink.value,
      ZOHO_DESK_SETUP: this.featureConfigForm.ZOHO_DESK_SETUP.value,
      ZOHO_SUB_CLIENT_PORTAL_LINK: this.featureConfigForm.ZOHO_SUB_CLIENT_PORTAL_LINK.value,
      /* eslint-disable camelcase */
      google_keys: this.setupForm.google_keys.value,
      linkedin_key: this.setupForm.linkedin_key.value,
      facebook_key: this.setupForm.facebook_key.value,
      /* eslint-enable camelcase */
      samlCert: this.featureConfigForm.samlCert.value,
      samlKey: this.featureConfigForm.samlKey.value,
      samlUrl: this.featureConfigForm.samlUrl.value,
      /* eslint-disable camelcase */
      features_configuration: {
        login_config: {
          get_started_help_link: this.featureConfigForm.login_config.value.get_started_help_link,
          login_with_facebook: this.setupForm.login_config.value.login_with_facebook,
          login_with_linkedin: this.setupForm.login_config.value.login_with_linkedin,
          login_with_google: this.setupForm.login_config.value.login_with_google
        },
        navbar_config: this.featureConfigForm.navbar_config.value,
        dashboard_config: this.featureConfigForm.dashboard_config.value,
        profile_config: this.featureConfigForm.profile_config.value
        /* eslint-enable camelcase */
      },
      BackgroundColor: '#' +  this.themeConfigForm.BackgroundColor.value?.hex,
      PrimaryColor: '#' + this.themeConfigForm.PrimaryColor.value?.hex,
      TextColor: '#' + this.themeConfigForm.TextColor.value?.hex,
      // eslint-disable-next-line camelcase
      theme_configuration: {
        buttons: {
          primary: {
            'background-color': '#' + this.themeConfigForm.buttons.value?.primary?.backgroundColor?.hex,
            'text-color': '#' + this.themeConfigForm.buttons.value?.primary?.textColor?.hex,
            'hover-background-color': '#' + this.themeConfigForm.buttons.value?.primary?.hoverBackgroundColor?.hex,
            'hover-text-color': '#' + this.themeConfigForm.buttons.value?.primary?.hoverTextColor?.hex,
          },
          secondary: {
            'background-color': '#' + this.themeConfigForm.buttons.value?.secondary?.backgroundColor?.hex,
            'text-color': '#' + this.themeConfigForm.buttons.value?.secondary?.textColor?.hex,
            'hover-background-color': '#' + this.themeConfigForm.buttons.value?.secondary?.hoverBackgroundColor?.hex,
            'hover-text-color': '#' + this.themeConfigForm.buttons.value?.secondary?.hoverTextColor?.hex,
          },
          login: {
            'background-color': '#' + this.themeConfigForm.buttons.value?.login?.backgroundColor?.hex,
            'text-color': '#' + this.themeConfigForm.buttons.value?.login?.textColor?.hex,
            'hover-background-color': '#' + this.themeConfigForm.buttons.value?.login?.hoverBackgroundColor?.hex,
            'hover-text-color': '#' + this.themeConfigForm.buttons.value?.login?.hoverTextColor?.hex,
          }
        }
      },
      AccountantDashboardURL: this.setupForm.AccountantDashboardURL.value,
      ClientDashboardURL: this.setupForm.ClientDashboardURL.value,
      FranchiseeINTURL: this.setupForm.FranchiseeINTURL.value,
      FranchiseePAYURL: this.setupForm.FranchiseePAYURL.value,
    };
    const uiConfig = {
      /* eslint-disable camelcase */
      header_html: this.templatesForm.header.value,
      footer_html: this.templatesForm.footer.value,
      login_html: this.templatesForm.editableLogin.value,
      /* eslint-enable camelcase */
      warningMessage: this.templatesForm.maintenanceMessage.value,
      warningOn: this.templatesForm.warningOn.value,
    };
    if (this.WhiteLabelPartnerDetails && this.WhiteLabelPartnerDetails.code) {
      const title = 'Edit White Label Partner';
      this.whiteLabelPartnerService
      .editWhiteLabelPartner({code: this.WhiteLabelPartnerDetails.code, franchisee: whiteLabelPartner, uiConfig}).then((response: any) => {
        this.commonUtility.displaySuccessToast(title, response.message);
        this.router.navigate(['partner/white-label-partner/all']);
        this.ngxService.stop();
        this.ngxService.stopBackground();
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
      });
    } else {
      const title = 'Add White Label Partner';
      this.whiteLabelPartnerService.addWhiteLabelPartner({franchisee: whiteLabelPartner, uiConfig}).then((response: any) => {
        this.commonUtility.displaySuccessToast(title, response.message);
        this.ngxService.stop();
        this.ngxService.stopBackground();
        this.router.navigate(['partner/white-label-partner/all']);
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
      });
    }
  }

  goBack() {
    window.history.back();
  }

}
