<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-md-12">
    <h2>{{pageHeading}}</h2>
    <ol class="breadcrumb">
      <li>
        <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
      </li>
      <li class="active">
        {{pageHeading}}
      </li>
    </ol>
  </div>
</div>
<div class="col-md-12 add-white-label-partner-container">
  <div class="row white-bg">
    <div class="ibox col-md-12">
      <div class="ibox-title">
        <div class="col-md-6 p-0 display-flex">
          <h5>{{pageHeading}}</h5>
        </div>
        <div class="col-md-6 p-0">
          <span class="float-end">

          </span>
        </div>
      </div>
    </div>
    <div class="ibox col-md-12 add-white-label-partner-filters-container">
      <div class="ibox-title">
        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="companyDetailsFormGroup" label="Company Details">
            <form [formGroup]="companyDetailsFormGroup">
              <div class="row">
                <h4 class="col-md-12 mb-3">Basic Information</h4>
                <div class="mb-3 col-md-4">
                  <label>Company Name <span class="required">*</span></label>
                  <input type="text" class="form-control" placeholder="CustomBooks" formControlName="CompanyName" />
                  <div
                    *ngIf="companyDetailsForm.CompanyName.invalid && (companyDetailsForm.CompanyName.dirty || companyDetailsForm.CompanyName.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.CompanyName.errors.required">Company Name is required.</div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <label>Admin Email <span class="required">*</span></label>
                  <input type="email" class="form-control" placeholder="demo@custombooks.com"
                    formControlName="FranchiseAdminEmail" />
                  <div
                    *ngIf="companyDetailsForm.FranchiseAdminEmail.invalid && (companyDetailsForm.FranchiseAdminEmail.dirty || companyDetailsForm.FranchiseAdminEmail.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.FranchiseAdminEmail.errors.required">Admin Email address is required.
                    </div>
                    <div *ngIf="companyDetailsForm.FranchiseAdminEmail.errors.email">Please enter a valid email address.
                    </div>
                  </div>

                </div>
                <div class="mb-3 col-md-4">
                  <label>Company URL/Website</label>
                  <input type="text" class="form-control" placeholder="https://www.custombooks.com"
                    formControlName="CompanyURL" />

                  <div
                    *ngIf="companyDetailsForm.CompanyURL.invalid && (companyDetailsForm.CompanyURL.dirty || companyDetailsForm.CompanyURL.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.CompanyURL.errors.pattern">Please enter a valid url. Make address started with http/https.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <h4 class="col-md-12 mb-3">Contact Details</h4>
                <div class="mb-3 col-md-4">
                  <label>Contact Number</label>
                  <ngx-intl-tel-input
                    [inputId]="'contactNumber'"
                    [cssClass]="'form-control intl-tel-input'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="SearchCountryField.All"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedStates"
                    [preferredCountries]="preferredCountries"
                    [maxLength]="10"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="ContactNumber"
                    formControlName="ContactNumber">
                  </ngx-intl-tel-input>
                  <div
                    *ngIf="companyDetailsForm.ContactNumber.invalid && (companyDetailsForm.ContactNumber.dirty || companyDetailsForm.ContactNumber.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.ContactNumber.errors.validatePhoneNumber">Please enter a valid
                      number.
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <label>Inquire Number</label>
                  <ngx-intl-tel-input
                    [inputId]="'inquireNumber'"
                    [cssClass]="'form-control intl-tel-input'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="SearchCountryField.All"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedStates"
                    [preferredCountries]="preferredCountries"
                    [maxLength]="10"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="InquireNumber"
                    formControlName="InquireNumber">
                  </ngx-intl-tel-input>
                  <div
                    *ngIf="companyDetailsForm.InquireNumber.invalid && (companyDetailsForm.InquireNumber.dirty || companyDetailsForm.InquireNumber.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.InquireNumber.errors.validatePhoneNumber">Please enter a valid
                      number.
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <label>HelpLine Number</label>
                  <ngx-intl-tel-input
                      [inputId]="'companyPhoneNumber'"
                      [cssClass]="'form-control intl-tel-input'"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="SearchCountryField.All"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.UnitedStates"
                      [preferredCountries]="preferredCountries"
                      [maxLength]="10"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      name="HelpLineNumber"
                      formControlName="HelpLineNumber">
                  </ngx-intl-tel-input>
                  <div
                    *ngIf="companyDetailsForm.HelpLineNumber.invalid && (companyDetailsForm.HelpLineNumber.dirty || companyDetailsForm.HelpLineNumber.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.HelpLineNumber.errors.validatePhoneNumber">Please enter a valid
                      number.
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <label>Contact Number Ext</label>
                  <input class="form-control" type="text" placeholder="" formControlName="ContactNumberExt" />
                </div>
                <div class="mb-3 col-md-4">
                  <label>Working Hours</label>
                  <input class="form-control" type="text" placeholder="M-F 6:00am-5:30pm Pacific Time"
                    formControlName="WorkingHours" />
                </div>
                <div class="mb-3 col-md-6">
                  <label>Support Email</label>
                  <input class="form-control" type="email" placeholder="support@custombooks.com"
                    formControlName="SupportEmail" />
                  <div
                    *ngIf="companyDetailsForm.SupportEmail.invalid && (companyDetailsForm.SupportEmail.dirty || companyDetailsForm.SupportEmail.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.SupportEmail.errors.email">Please enter a valid email address.
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-6">
                  <label>Help Email</label>
                  <input class="form-control" type="email" placeholder="help@custombooks.com"
                    formControlName="helpEmail" />
                  <div
                    *ngIf="companyDetailsForm.helpEmail.invalid && (companyDetailsForm.helpEmail.dirty || companyDetailsForm.helpEmail.touched)"
                    class="invalid-feedback">
                    <div *ngIf="companyDetailsForm.helpEmail.errors.email">Please enter a valid email address.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" formGroupName="PhysicalAddress">
                <h4 class="col-md-12 mb-3">Physical Address</h4>
                <div class="mb-3 col-md-6">
                  <label>Address Line 1</label>
                  <input class="form-control" type="text" placeholder="268 Bush Street"
                    formControlName="AddressLine1" />
                </div>
                <div class="mb-3 col-md-6">
                  <label>Address Line 2</label>
                  <input class="form-control" type="text" placeholder="Suite 2829" formControlName="AddressLine2" />
                </div>
                <div class="mb-3 col-md-4">
                  <label>City</label>
                  <input class="form-control" type="text" placeholder="San Francisco" formControlName="City" />
                </div>
                <div class="mb-3 col-md-4">
                  <label>State</label>
                  <input class="form-control" type="text" placeholder="CA" formControlName="State" />
                </div>
                <div class="mb-3 col-md-4">
                  <label>Zip Code</label>
                  <input class="form-control" type="text" placeholder="94104" formControlName="Zip" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-primary" matStepperNext
                    [disabled]="!companyDetailsFormGroup.valid">Next</button>
                  <button class="btn-default float-end" mat-button (click)="goBack()">Cancel</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="featureConfigFormGroup" label="Portal Features Setup">
            <form [formGroup]="featureConfigFormGroup">
              <div>
                <div class="row" formGroupName="profile_config">
                  <h4 class="col-md-12">Profile Features</h4>
                  <div class="mb-3 col-md-4 mb-2">
                    <mat-checkbox name="showPersonalDetails" class="example-margin" formControlName="personal_details">
                      Show Personal Details</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2">
                    <mat-checkbox name="showCompanyDetails" class="example-margin" formControlName="company_details">
                      Show Company Details</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2">
                    <mat-checkbox name="showMFASetup" class="example-margin" formControlName="mfa_setup">Show MFA Setup
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <h4 class="col-md-12">Dashboard Features</h4>
                  <div class="mb-3 col-md-4 mb-2" formGroupName="navbar_config">
                    <mat-checkbox name="showNotifications" class="example-margin" formControlName="notification">Show
                      Notifications</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2" formGroupName="navbar_config">
                    <mat-checkbox name="showMessages" class="example-margin" formControlName="inbox">Show Messages
                    </mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2" formGroupName="dashboard_config">
                    <mat-checkbox name="showAddNewClient" class="example-margin" formControlName="add_client">Show Add
                      New Client</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2" formGroupName="dashboard_config">
                    <mat-checkbox name="showMultiEntityReport" class="example-margin"
                      formControlName="multi_entity_reports">Show Multi-Entity Report</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-4 mb-2" formGroupName="dashboard_config">
                    <mat-checkbox name="showPlayground" class="example-margin" formControlName="playground">Show
                      Playground</mat-checkbox>
                  </div>
                  <div class="mb-3 col-md-12 mb-2" formGroupName="navbar_config">
                    <mat-checkbox name="showContactUsLink" class="example-margin" formControlName="contact_us_link">Show
                      Contact Us Link</mat-checkbox>
                  </div>
                  <div *ngIf="
                                featureConfigFormGroup.controls.navbar_config.value
                                  .contact_us_link
                              " class="full-width-box col-md-12">
                    <div class="mb-3 col-md-4">
                      <label>Contact Us Link <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="ContactUsLink"
                        placeholder="https://www.custombooks.com/about-contact/" />
                      <div
                        *ngIf="featureConfigForm.ContactUsLink.invalid && (featureConfigForm.ContactUsLink.dirty || featureConfigForm.ContactUsLink.touched)"
                        class="invalid-feedback">
                        <div *ngIf="featureConfigForm.ContactUsLink.errors.required">Contact Us Link is required.</div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-md-12 mb-2" formGroupName="navbar_config">
                    <mat-checkbox name="showAccountLink" class="example-margin" formControlName="zoho_account">Show
                      Account Link</mat-checkbox>
                  </div>
                  <div *ngIf="
                                featureConfigFormGroup.controls.navbar_config.value.zoho_account
                              " class="full-width-box col-md-12">
                    <div class="mb-3 col-md-6">
                      <label>Zoho Sub Customer Portal Link <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="ZOHO_SUB_CLIENT_PORTAL_LINK"
                        placeholder="https://subscriptions.zoho.com/portal/testacs" />
                      <div
                        *ngIf="featureConfigForm.ZOHO_SUB_CLIENT_PORTAL_LINK.invalid && (featureConfigForm.ZOHO_SUB_CLIENT_PORTAL_LINK.dirty || featureConfigForm.ZOHO_SUB_CLIENT_PORTAL_LINK.touched)"
                        class="invalid-feedback">
                        <div *ngIf="featureConfigForm.ZOHO_SUB_CLIENT_PORTAL_LINK.errors.required">Zoho Sub Customer
                          Portal Link is required.</div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>SAML URL <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="samlUrl" placeholder="" />
                      <div
                        *ngIf="featureConfigForm.samlUrl.invalid && (featureConfigForm.samlUrl.dirty || featureConfigForm.samlUrl.touched)"
                        class="invalid-feedback">
                        <div *ngIf="featureConfigForm.samlUrl.errors.required">SAML URL is required.</div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>SAML Certificate <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="samlCert" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                      <div
                        *ngIf="featureConfigForm.samlCert.invalid && (featureConfigForm.samlCert.dirty || featureConfigForm.samlCert.touched)"
                        class="invalid-feedback">
                        <div *ngIf="featureConfigForm.samlCert.errors.required">SAML Certificate is required.</div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>SAML Key <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="samlKey" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                      <div
                        *ngIf="featureConfigForm.samlKey.invalid && (featureConfigForm.samlKey.dirty || featureConfigForm.samlKey.touched)"
                        class="invalid-feedback">
                        <div *ngIf="featureConfigForm.samlKey.errors.required">SAML Key is required.</div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-md-12 mb-2" formGroupName="navbar_config">
                    <mat-checkbox name="showHelpLink" class="example-margin" formControlName="help_link">Show Zoho Desk
                      Support Link</mat-checkbox>
                  </div>
                  <div *ngIf="
                                featureConfigFormGroup.controls.navbar_config.value.help_link
                              " formGroupName="ZOHO_DESK_SETUP" class="full-width-box col-md-12">
                    <div class="mb-3 col-md-6">
                      <label>Auth URL <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="REMOTE_AUTH_URL"
                        placeholder="https://help.custombooks.com/support/RemoteAuth" />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>Auth Key <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="REMOTE_AUTH_KEY" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <h4 class="col-md-12">Login Features</h4>
                  <div class="mb-3 col-md-12 mb-2" formGroupName="login_config">
                    <mat-checkbox name="getStartedHelpLink" class="example-margin"
                      formControlName="get_started_help_link">
                      Show Get Started Help Link</mat-checkbox>
                  </div>
                  <div *ngIf="featureConfigFormGroup.controls.login_config.value.get_started_help_link" class="full-width-box col-md-12">
                    <div class="mb-3 col-md-4">
                      <label>Link <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="GetStartedHelpLink"
                        placeholder="https://help.custombooks.com/portal/kb/custombooks/getting-started-guide" />
                    </div>
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-md-12 btn-group mt-3 mb-5">
                  <button class="btn btn-primary me-2" matStepperPrevious>Back</button>
                  <button class="btn btn-primary" matStepperNext
                    [disabled]="!featureConfigFormGroup.valid">Next</button>
                  <button class="btn btn-default float-end" (click)="goBack()">Cancel</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="themeConfigFormGroup" label="Portal Theme Setup">
            <form [formGroup]="themeConfigFormGroup">
              <div class="row">
                <h4 class="col-md-12 mb-3">Theme Colors</h4>
                <div class="col-md-12">
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Primary Color</mat-label>
                        <input matInput [ngxMatColorPicker]="primaryColorPicker" formControlName="PrimaryColor" />
                        <ngx-mat-color-toggle matSuffix [for]="primaryColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #primaryColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="textColorPicker" formControlName="TextColor" />
                        <ngx-mat-color-toggle matSuffix [for]="textColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #textColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="backgroundColorPicker" formControlName="BackgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="backgroundColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #backgroundColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" formGroupName="buttons">
                <h4 class="col-md-12 mb-3">Primary Button</h4>
                <div class="col-md-6" formGroupName="primary">
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="primaryBtnBackgroundPicker"
                          formControlName="backgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="primaryBtnBackgroundPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #primaryBtnBackgroundPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="primaryBtnTextColorPicker" formControlName="textColor" />
                        <ngx-mat-color-toggle matSuffix [for]="primaryBtnTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #primaryBtnTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="primaryHoverBackgroundColorPicker"
                          formControlName="hoverBackgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="primaryHoverBackgroundColorPicker">
                        </ngx-mat-color-toggle>
                        <ngx-mat-color-picker #primaryHoverBackgroundColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="primaryHoverTextColorPicker"
                          formControlName="hoverTextColor" />
                        <ngx-mat-color-toggle matSuffix [for]="primaryHoverTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #primaryHoverTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="config-btn-primary">Primary Button</button>
                </div>
              </div>
              <div class="row" formGroupName="buttons">
                <h4 class="col-md-12 mb-3">Secondary Button</h4>
                <div class="col-md-6">
                  <div class="row" formGroupName="secondary">
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="secondaryBtnBackgroundPicker"
                          formControlName="backgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="secondaryBtnBackgroundPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #secondaryBtnBackgroundPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="secondaryBtnTextColorPicker" formControlName="textColor" />
                        <ngx-mat-color-toggle matSuffix [for]="secondaryBtnTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #secondaryBtnTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="secondaryHoverBackgroundColorPicker"
                          formControlName="hoverBackgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="secondaryHoverBackgroundColorPicker">
                        </ngx-mat-color-toggle>
                        <ngx-mat-color-picker #secondaryHoverBackgroundColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="secondaryHoverTextColorPicker"
                          formControlName="hoverTextColor" />
                        <ngx-mat-color-toggle matSuffix [for]="secondaryHoverTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #secondaryHoverTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="config-btn-default">Secondary Button</button>
                </div>
              </div>
              <div class="row" formGroupName="buttons">
                <h4 class="col-md-12 mb-3">Login Button</h4>
                <div class="col-md-6">
                  <div class="row" formGroupName="login">
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="loginBtnBackgroundPicker"
                          formControlName="backgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="loginBtnBackgroundPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #loginBtnBackgroundPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="loginBtnTextColorPicker" formControlName="textColor" />
                        <ngx-mat-color-toggle matSuffix [for]="loginBtnTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #loginBtnTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Background Color</mat-label>
                        <input matInput [ngxMatColorPicker]="loginHoverBackgroundColorPicker"
                          formControlName="hoverBackgroundColor" />
                        <ngx-mat-color-toggle matSuffix [for]="loginHoverBackgroundColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #loginHoverBackgroundColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                    <div class="mb-3 col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Hover Text Color</mat-label>
                        <input matInput [ngxMatColorPicker]="loginHoverTextColorPicker"
                          formControlName="hoverTextColor" />
                        <ngx-mat-color-toggle matSuffix [for]="loginHoverTextColorPicker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #loginHoverTextColorPicker></ngx-mat-color-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="btn config-btn-login">Login Button</button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 btn-group mt-3 mb-5">
                  <button class="btn btn-primary me-2" matStepperPrevious>Back</button>
                  <button class="btn btn-primary" matStepperNext
                    *ngIf="whiteLabelPartnerTemplateAccess || whiteLabelPartnerSetupAccess"
                    [disabled]="!themeConfigFormGroup.valid">Next</button>
                  <button class="btn btn-primary" (click)="saveWhiteLabelPartnerDetails()"
                    *ngIf="!whiteLabelPartnerTemplateAccess && !whiteLabelPartnerSetupAccess"
                    [disabled]="!themeConfigFormGroup.valid">Submit</button>
                  <button class="btn btn-default float-end" (click)="goBack()">Cancel</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="templatesFormGroup" label="Hosted Page Template(s)"
            *ngIf="whiteLabelPartnerTemplateAccess">
            <form [formGroup]="templatesFormGroup" class="white-label-templates">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <mat-accordion multi>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Header Template
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="ibox-content">
                        <div [ngxSummernote]="config" formControlName="header"></div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Footer Template
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="ibox-content">
                        <div [ngxSummernote]="config" formControlName="footer"></div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Login Template
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="ibox-content">
                        <div [ngxSummernote]="config" formControlName="editableLogin"></div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Maintenance Template
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="ibox-content">
                        <div [ngxSummernote]="config" formControlName="maintenanceMessage"></div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 btn-group mt-3 mb-5">
                  <button class="btn btn-primary me-2" matStepperPrevious
                    [disabled]="!templatesFormGroup.valid">Back</button>
                  <button class="btn btn-primary me-2" matStepperNext *ngIf="whiteLabelPartnerSetupAccess"
                    [disabled]="!templatesFormGroup.valid">Next</button>
                  <button class="btn btn-primary me-2" (click)="saveWhiteLabelPartnerDetails()"
                    *ngIf="!whiteLabelPartnerSetupAccess">Submit</button>
                  <button class="btn btn-default float-end" (click)="goBack()">Cancel</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="setupFormGroup" label="Technical Setup" *ngIf="whiteLabelPartnerSetupAccess">
            <form [formGroup]="setupFormGroup">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="mb-3">Projects</h4>
                  <div class="mb-3 mb-2">
                    <mat-checkbox name="enableAccountantDashboard" class="example-margin"
                      formControlName="enableAccountantDashboard">Enable Partner Portal</mat-checkbox>
                  </div>

                  <div *ngIf="setupFormGroup.controls.enableAccountantDashboard.value" class="full-width-box">
                    <div class="mb-3 col-md-6">
                      <label>Domain <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="AccountantDashboardURL" />
                    </div>
                    <div class="mb-3 col-md-4">
                      <label>Payment Project <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="FranchiseePAYURL" />
                    </div>
                    <div class="mb-3 col-md-4">
                      <label>Intacs Project <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="FranchiseeINTURL" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 mb-2">
                    <mat-checkbox name="enableClientDashboard" class="example-margin"
                      formControlName="enableClientDashboard">Enable Client Dashboard</mat-checkbox>
                  </div>

                  <div *ngIf="setupFormGroup.controls.enableClientDashboard.value" class="full-width-box">
                    <div class="mb-3 col-md-6">
                      <label>Domain <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="ClientDashboardURL" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 mb-2" formGroupName="login_config">
                    <mat-checkbox name="loginWithFacebook" class="example-margin" formControlName="login_with_facebook">
                      Login with Facebook</mat-checkbox>
                  </div>

                  <div *ngIf="
                              setupFormGroup.controls.login_config.value
                                  .login_with_facebook
                              " formGroupName="facebook_key" class="full-width-box">
                    <div class="mb-3 col-md-6">
                      <label>Application Version <span class="required">*</span></label>
                      <input type="text" class="form-control" placeholder="v3.3" formControlName="apiVersion" />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>Application Id <span class="required">*</span></label>
                      <input type="text" class="form-control" placeholder="2101739840122558" formControlName="appId" />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>Application Secret <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="appSecret" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-12">
                      <label>Redirect URL <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="RedirectURL"
                        placeholder="https://dev.custombooks.com/facebookLogin" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 mb-2" formGroupName="login_config">
                    <mat-checkbox name="loginWithGoogle" class="example-margin" formControlName="login_with_google">
                      Login with Google</mat-checkbox>
                  </div>
                  <div *ngIf="
                              setupFormGroup.controls.login_config.value
                                  .login_with_google
                              " formGroupName="google_keys" class="full-width-box">
                    <div class="mb-3 col-md-6">
                      <label>Client Id <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="ClientID"
                        placeholder="170401564523-md6ajj3q9gfh6psj9il7ht50ptps88em.apps.googleusercontent.com" />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>Client Secret <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="ClientSecret" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-12">
                      <label>Redirect URL <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="RedirectURL"
                        placeholder="https://dev.custombooks.com/googleLogin" />
                    </div>
                  </div>
                  <div class="mb-3  mb-2" formGroupName="login_config">
                    <mat-checkbox name="loginWithLinkedIn" class="example-margin" formControlName="login_with_linkedin">
                      Login with LinkedIn</mat-checkbox>
                  </div>
                  <div *ngIf="
                              setupFormGroup.controls.login_config.value
                                  .login_with_linkedin
                              " formGroupName="linkedin_key" class="full-width-box">
                    <div class="mb-3 col-md-6">
                      <label>Client Id <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="ClientID"
                        placeholder="170401564523-md6ajj3q9gfh6psj9il7ht50ptps88em.apps.googleusercontent.com" />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>Client Secret <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="ClientSecret" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label>CSRF String <span class="required">*</span></label>
                      <div class="input-group">
                        <input class="form-control" autocomplete="off" [type]="hideSecrets ? 'password' : 'text'"
                          formControlName="CSRFString" />
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa"
                              [ngClass]="{'fa-eye': hideSecrets, 'fa-eye-slash': !hideSecrets}"
                              (click)="hideSecrets = !hideSecrets"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-12">
                      <label>Redirect URL <span class="required">*</span></label>
                      <input type="text" class="form-control" formControlName="RedirectURL"
                        placeholder="https://dev.custombooks.com/linkedInLogin" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="btn-group col-md-12 mb-5 p-0">
              <button class="btn btn-primary" (click)="saveWhiteLabelPartnerDetails()"
                [disabled]="!setupFormGroup.valid">Submit</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>
