import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ExportService } from '@app/services/export.service';
import { LogService } from '@app/services/log.service';
import { PopupService } from '@app/services/popup.service';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import * as dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.css']
})
export class EmailLogsComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;

  searchString: string;
  selectedStatus: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  emailLogs: [];
  fromShowing: any;
  toShowing: any;
  statusList = [
    {id: 1, label: 'Processed', value: 'Processed', key: 'processed', class: 'dot dot-processed-event'},
    {id: 2, label: 'Dropped', value: 'Dropped', key: 'dropped', class: 'dot dot-dropped-event'},
    {id: 3, label: 'Delivered', value: 'Delivered', key: 'delivered', class: 'dot dot-delivered-event'},
    {id: 4, label: 'Deferred', value: 'Deferred', key: 'deferred', class: 'dot dot-deferred-event'},
    {id: 5, label: 'Bounce', value: 'Bounce', key: 'bounce', class: 'dot dot-bounce-event'},
    {id: 6, label: 'Blocked', value: 'Blocked', key: 'blocked', class: 'dot dot-blocked-event'},
    {id: 7, label: 'Open', value: 'Open', key: 'open', class: 'dot dot-open-event'},
    {id: 8, label: 'Click', value: 'Click', key: 'Click', class: 'dot dot-click-event'},
    {id: 9, label: 'Spam Report', value: 'Spam Report', key: 'spamreport', class: 'dot dot-spam-report-event'},
    {id: 10, label: 'Unsubscribe', value: 'Unsubscribe', key: 'unsubscribe', class: 'dot dot-unsubscribe-event'},
    {id: 11, label: 'Group Unsubscribe', value: 'Group Unsubscribe', key: 'group_unsubscribe',
    class: 'dot dot-group-unsubscribe-event'},
    {id: 12, label: 'Group Resubscribe', value: 'Group Resubscribe', key: 'group_resubscribe',
    class: 'dot dot-group-resubscribe-event'},
  ];
  columns = [
    {id: 0, name: 'sentDate', label: 'Sent Date', sort: -1, hide: 0},
    {id: 1, name: 'status', label: 'Event', sort: 0, hide: 0},
    {id: 2, name: 'user', label: 'User', sort: 0, hide: 0},
    {id: 3, name: 'accountNumber', label: 'Account Number', sort: 0, hide: 0},
    {id: 4, name: 'subject', label: 'Subject', sort: 0, hide: 0},
    {id: 5, name: 'to', label: 'To', sort: 0, hide: 0},
    {id: 6, name: 'from', label: 'From', sort: 0, hide: 0},
    {id: 7, name: 'cc', label: 'cc', sort: 0, hide: 0},
    {id: 8, name: 'bcc', label: 'bcc', sort: 0, hide: 0},
    {id: 9, name: 'lastUpdatedDate', label: 'Last Updated Date', sort: 0, hide: 0},
    {id: 10, name: 'deliveredDate', label: 'Delivered At', sort: 0, hide: 0},
  ];
  activeIndex: number;
  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(29, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  maxDate = dayjs();
  companyName: string;
  // graph settings
  selectedGraphType: string;
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [],
    labels: []
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    aspectRatio: 3,
    scales: {
      x: {},
      y: {
        min: 0,
        beginAtZero: true
      }
    }
  };
  @ViewChildren('emailLogTabs') emailLogTabs: QueryList<MatTabGroup>;
  @ViewChild('allSelectedStatus') private allSelectedStatus: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private logService: LogService,
    private commonUtility: CommonUtilityService,
    private popupService: PopupService,
    private logger: NGXLogger,
    private exportService: ExportService
  ) {
    this.pageHeading = 'Email Logs';
    this.searchString = '';
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 0, name: 'sentDateISODate', label: 'Sent Date', sort: -1, hide: 0},
      {id: 1, name: 'status', label: 'Event', sort: 0, hide: 0},
      {id: 2, name: 'email', label: 'User', sort: 0, hide: 0},
      {id: 3, name: 'accountNumber', label: 'Account Number', sort: 0, hide: 0},
      {id: 4, name: 'subject', label: 'Subject', sort: 0, hide: 0},
      {id: 5, name: 'to', label: 'To', sort: 0, hide: 0},
      {id: 6, name: 'from', label: 'From', sort: 0, hide: 0}
    ];
    this.selectedSort = {key: 'Sent Date', value: -1};
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.activeIndex = 0;

    this.selectedGraphType = 'day';
    this.dateRange = {
      start: dayjs().subtract(29, 'days').startOf('day'),
      end: dayjs().endOf('day'),
    };
    this.selectedDateRange = {
      start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().endOf('day').format('MM-DD-YYYY'),
    };
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `Email Logs | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllEmailLogs(this.pageNo);
    this.generateGraph();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  clearSearch() {
    this.searchString = '';
    this.getAllEmailLogs(this.pageNo);
    this.generateGraph();
  }

  onChangeSearchText() {
    this.getAllEmailLogs(this.pageNo);
    this.generateGraph();
  }

  changeStatusFilter() {
    this.getAllEmailLogs(this.pageNo);
    this.generateGraph();
  }

  toggleAllTypeStatus() {
    if (this.allSelectedStatus.selected) {
      this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    } else {
      this.selectedStatus = [];
    }
    this.changeStatusFilter();
  }

  statusTouchPerOne() {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
    }
    if (this.selectedStatus.length === this.statusList.length) {
        this.allSelectedStatus.select();
    }
    this.changeStatusFilter();
  }

  resetStatusSelection() {
    this.allSelectedStatus.select();
    this.selectedStatus =
        [...this.statusList.map(item => item.value), 0];
    this.changeStatusFilter();
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllEmailLogs(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllEmailLogs(this.pageNo);
  }

  openStatusInfo() {
    this.popupService.openEmailLogStatusInfoPopup();
  }

  onTabChange(event: any){
    this.activeIndex = event.index;
    this.emailLogTabs.forEach(tab => {
      tab.realignInkBar();
   });
  }

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.getAllEmailLogs(this.pageNo);
      this.generateGraph();
    }
  }
  // #endregion

  getAllEmailLogs(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedStatus: this.selectedStatus,
      selectedSort: this.selectedSort,
      selectedDateRange: this.selectedDateRange
    };
    this.logService.getAllEmailLogs(requestParams).then((emailLogsListResponse: any) => {
      this.logger.info('INFO: -> EmailLogsComponent -> this.logService.getAllEmailLogs -> emailLogsListResponse', emailLogsListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Email logs List refreshed.');
      this.emailLogs = emailLogsListResponse.emailLogs;
      if (emailLogsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < emailLogsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        emailLogsListResponse.totalCount);
      }
      this.totalCount = emailLogsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> EmailLogsComponent -> this.logService.getAllEmailLogs -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  private generateGraph(): void {
    const requestParams = {
      searchText: this.searchString,
      selectedStatus: this.selectedStatus,
      selectedDateRange: this.selectedDateRange,
      selectedGraphType: this.selectedGraphType
    };
    this.logService.getEmailLogsGraphPlots(requestParams).then((response: any) => {
      this.logger.info('INFO: -> EmailLogsComponent -> this.logService.getEmailLogsGraphPlots -> response', response);
      this.lineChartData = {
        datasets: response.data.map((data, index) => (
          Object.assign(data, response.colors[index], {
            tension: 0.5,
            fill: true
          }))
        ),
        labels: response.labels
      };
    }).catch(error => {
      this.logger.error('ERROR: -> EmailLogsComponent -> this.logService.getEmailLogsGraphPlots -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails('Generate Graph', error.error);
    });
  }

  changeGraphType(type: string) {
    this.selectedGraphType = type;
    this.generateGraph();
  }

  exportAction(option) {
    const fileName = 'all_email_logs';
    const exportData = [];
    this.emailLogs.forEach(item => {
      const tempJson = {};
      this.selectedColumns.forEach(column => {
        if (typeof item[column.name] === 'object') {
          tempJson[column.label] = item[column.name][column.name1] &&
            item[column.name][column.name1] !== 'Not Available' ? item[column.name][column.name1] : '';
        } else {
          tempJson[column.label] = item[column.name] && item[column.name] !== 'Not Available' ? item[column.name] : '';
        }
      });
      exportData.push(tempJson);
    });
    if (option === 'excel') {
      this.exportService.exportAsExcelFile(exportData, fileName);
    } else if (option === 'csv') {
      this.exportService.exportToCsvFile(exportData, fileName);
    } else if (option === 'doc') {
      this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
    }
  }

  refreshData() {
    this.getAllEmailLogs(this.currentPage);
    this.generateGraph();
  }
}
