<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 email-logs-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <!-- <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');"> <i class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');"> <i class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Doc" (click)="exportAction('doc');"> <i class="fa fa-file-word-o"></i>
                        </a> -->
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 email-logs-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 email-logs-filter-group">
                            <label for="search"
                                matTooltip="You can search by User, Account Number fields">Global Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Event">Event</label> <i
                                class="fa fa-info-circle ms-2" (click)="openStatusInfo()"
                                matTooltip="Event Details"></i>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedStatus.length"><b>Select Event</b></mat-label>
                                <mat-select [(ngModel)]="selectedStatus" multiple>
                                    <mat-option #allSelectedType (click)="toggleAllTypeStatus()" [value]="0">All
                                    </mat-option>
                                    <mat-option *ngFor="let item of statusList" [value]="item.value"
                                        (click)="statusTouchPerOne()">{{item.label}}
                                        <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3">
                            <label>Date Range <span class="required">*</span></label>
                            <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                [locale]="{applyLabel: 'ok', format: 'MM-DD-YYYY'}" [maxDate]="maxDate"
                                [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                (datesUpdated)="datesUpdated($event)" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 email-logs-filter-group" *ngIf="activeIndex === 0">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div *ngIf="activeIndex === 0">
                            <div class="mb-3 account-filter-group item-per-page-option">
                                Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                    (selectionChange)="changeDisplayPerPageItem($event)">
                                    <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                    </mat-option>
                                </mat-select>&nbsp; email log(s)
                            </div>
                            <p class="mb-0">
                                Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} email log(s)
                            </p>
                            <pagination-controls id="all-email-logs" (pageChange)="getAllEmailLogs($event)"
                                responsive="true" previousLabel="Previous" nextLabel="Next" maxSize="3">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row filtered-by-box">
                <div class="col-md-12">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show date-range-filter ms-2">
                        <strong>Date Range:</strong> {{selectedDateRange.start}} - {{selectedDateRange.end}}
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedStatus.includes(0)">
                        <strong>Event:</strong> ({{selectedStatus.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetStatusSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12 mt-2" *ngIf="activeIndex === 0">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending': 'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row email-logs-result-container">
        <mat-tab-group #emailLogTabs [dynamicHeight]="true" mat-align-tabs="center" [selectedIndex]="activeIndex"
                       mat-stretch-tabs="false" mat-align-tabs="center"
            (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">table_chart</mat-icon> Data
                </ng-template>
                <div class="ibox col-md-12 email-logs-table">
                    <div class="ibox-title">
                        <div class="email-logs-list-box table-responsive">
                            <table
                                class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                                id="email-logs-table">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let column of columns; let i = index">
                                            <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                                [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                                (click)="sortBy(column.label)">{{column.label}}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let emailLog of emailLogs | paginate: { id: 'all-email-logs', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                        <td *ngIf="checkColumnVisibility(columns[0])">
                                            <span *ngIf="emailLog.sentDate">{{emailLog.sentDate}}</span>
                                            <span *ngIf="!emailLog.sentDate" class="not-available">Not
                                                Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[1])">
                                            <span *ngIf="emailLog.event"><span
                                                    class="badge badge-{{emailLog.eventClass}}-event">{{emailLog.event}}</span></span>
                                            <span *ngIf="!emailLog.event" class="not-available">Not
                                                Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[2])">
                                            <span *ngIf="emailLog.user">{{emailLog.user}}</span>
                                            <span *ngIf="!emailLog.user" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[3])">
                                            <span *ngIf="emailLog.accountNumber">{{emailLog.accountNumber}}</span>
                                            <span *ngIf="!emailLog.accountNumber" class="not-available">Not
                                                Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[4])">
                                            <span *ngIf="emailLog.subject">{{emailLog.subject}}</span>
                                            <span *ngIf="!emailLog.subject" class="not-available">Not
                                                Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[5])">
                                            <span *ngIf="emailLog.to">{{emailLog.to}}</span>
                                            <span *ngIf="!emailLog.to" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[6])">
                                            <span *ngIf="emailLog.from">{{emailLog.from}}</span>
                                            <span *ngIf="!emailLog.from" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[7])">
                                            <span *ngIf="emailLog.cc">{{emailLog.cc}}</span>
                                            <span *ngIf="!emailLog.cc" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[8])">
                                            <span *ngIf="emailLog.bcc">{{emailLog.bcc}}</span>
                                            <span *ngIf="!emailLog.bcc" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[9])">
                                            <span *ngIf="emailLog.lastUpdatedDate">{{emailLog.lastUpdatedDate}}</span>
                                            <span *ngIf="!emailLog.lastUpdatedDate" class="not-available">Not
                                                Available</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!emailLogs || emailLogs.length === 0" class="text-center">
                                        <td colspan="10">No email log(s) available at this time</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">insert_chart</mat-icon> Graph
                </ng-template>
                <div class="ibox col-md-12">
                    <div class="btn-group pt-3 float-end">
                        <button class="btn btn-sm btn-primary me-2" (click)="changeGraphType('day')">Day</button>
                        <button class="btn btn-sm btn-primary me-2" (click)="changeGraphType('month')">Month</button>
                        <button class="btn btn-sm btn-primary" (click)="changeGraphType('year')">Year</button>
                    </div>
                    <canvas baseChart width="3" height="1"
                            [data]="lineChartData"
                            [options]="lineChartOptions"
                            [legend]="true"
                            [type]="'line'">
                    </canvas>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
