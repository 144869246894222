import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Country } from '@app/models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  api: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getCountriesList() {
    return this.httpClient.get<Country[]>(this.api + '/get-country-list').toPromise();
  }
}
