import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService implements OnDestroy {

  cyrillicPattern = environment.CYRILLICPATTERN;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private toastr: ToastrService
  ) { }

  displayErrorToastWithTechDetails(title: string, error: any): void {
    let errorMessage = error?.userMessage || environment.COMMON_ERROR_MESSAGE;
    const code = error.code || '';
    const message = error.message || '';
    if (code && message) {
      errorMessage += `<br><br><a class="toast-tech-link">Click here for Technical Details</a>
                       <br><span class="toast-tech-details toast-tech-hide"><b>Error Code:</b> ${code}
                       <br><b>Error Description:</b> ${message}</span>`;
    }
    if (this.cyrillicPattern.test(errorMessage)) {
      this.toastr.error('The application has encountered an unknown error. Our technical staff have ' +
        'been automatically notified and will be looking into this asap.', title);
    } else {
      this.toastr.error(errorMessage, title, {enableHtml: true}).onTap
        .pipe(
          takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          $('.toast-tech-details').toggleClass('toast-tech-hide');
        });
    }
  }

  displaySuccessToast(title: string, message: string): void {
    this.toastr.success(message, title, {
      timeOut: 10000,
      closeButton: true,
      tapToDismiss: true,
      disableTimeOut: false,
      enableHtml: true
    });
  }

  displayErrorToast(title: string, message: string): void {
    if (this.cyrillicPattern.test(message)) {
      this.toastr.error('The application has encountered an unknown error. ' +
        'Our technical staff have been automatically notified and will be looking into this asap.', title);
    } else {
      this.toastr.error(message, title, {
        enableHtml: true
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
